export const tempClubList = [
    {
        uid: "1",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-1231233424"
        },
        isManager: true,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "2",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: true,
        isBinded: false,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "3",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "4",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "5",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "6",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "7",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
    {
        uid: "8",
        image: "/images/avatar.png",
        owner: {
            avatar: "/images/avatar.png",
            name: "Guest-123123"
        },
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        key: 5,
    },
];

export const battleList = [
    {
        uid: "1",
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        isManager: true,
        isBinded: true,
        price: 123,
        energy: 1234,
        keys: 5,
        rank: 1,
    },
    {
        uid: "2",
        avatar: "/images/avatar.png",
        name: "Guest-321321",
        isManager: true,
        isBinded: false,
        price: 123,
        energy: 1234,
        keys: 5,
        rank: 2,
    },
    {
        uid: "3",
        avatar: "/images/avatar.png",
        name: "Guest-312312",
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        keys: 5,
        rank: 3,
    },
    {
        uid: "4",
        avatar: "/images/avatar.png",
        name: "Guest-444444",
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        keys: 5,
        rank: 4,
    },
    {
        uid: "5",
        avatar: "/images/avatar.png",
        name: "Guest-555555",
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        keys: 5,
        rank: 5,
    },
    {
        uid: "6",
        avatar: "/images/avatar.png",
        name: "Guest-555555",
        isManager: false,
        isBinded: true,
        price: 123,
        energy: 1234,
        keys: 5,
        rank: 5,
    },
];

export const list = [
    {
        bg: "/images/9.webp",
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 1,
        trend: 0.1223545,
        isBinded: false,
        isLocked: true,
    },
    {
        bg: "/images/8.webp",
        avatar: "/images/avatar.png",
        name: "Guest-321321",
        price: 2,
        trend: -0.12,
        isBinded: true,
        isLocked: true,
    },
    {
        bg: "/images/3.webp",
        avatar: "/images/avatar.png",
        name: "Guest-213213",
        price: 3,
        trend: 0,
        isBinded: false,
        isLocked: false,
    },
    {
        bg: "/images/3.webp",
        avatar: "/images/avatar.png",
        name: "Guest-213213",
        price: 3,
        trend: 0,
        isBinded: false,
        isLocked: false,
    },
    {
        bg: "/images/3.webp",
        avatar: "/images/avatar.png",
        name: "Guest-213213",
        price: 3,
        trend: 0,
        isBinded: false,
        isLocked: false,
    },
];

export const wishList = [
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 1,
        trend: 0.12235,
        id: '@BitCloutCat',
        count: 25,
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        trend: 0.12235,
        id: '@BitCloutCat',
        count: 25,
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        trend: 0.12235,
        id: '@BitCloutCat',
        count: 25,
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        trend: -0.1223,
        id: '@BitCloutCat',
        count: 25,
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        trend: 0.12235,
        id: '@BitCloutCat',
        count: 25,
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        trend: 0.12235,
        id: '@BitCloutCat',
        count: 25,
    }
]

export const tempQuests = [
    {
        image: "/images/1.webp",
        title: "Quest 1",
        describe: "Description 1",
        LevelRequired: 1,
        ProductionRate: 1,
        CurrentEfficiency: 1,
        MissionDuration: 10,
        Yield: 1000,
        ClubEarnings: 10000,
        MyEarnings: 1666,
        DispatchedClubs: 0,
    },
    {
        image: "/images/2.webp",
        title: "Quest 2",
        describe: "Description 2",
        LevelRequired: 2,
        ProductionRate: 1,
        CurrentEfficiency: 1,
        MissionDuration: 10,
        Yield: 2000,
        ClubEarnings: 20000,
        MyEarnings: 2666,
        DispatchedClubs: 0,
    },
    {
        image: "/images/3.webp",
        title: "Quest 3",
        describe: "Description 3",
        LevelRequired: 3,
        ProductionRate: 1,
        CurrentEfficiency: 1,
        MissionDuration: 10,
        Yield: 3000,
        ClubEarnings: 30000,
        MyEarnings: 3666,
        DispatchedClubs: 0,
    },
    {
        image: "/images/8.webp",
        title: "Quest 4",
        describe: "Description 4",
        LevelRequired: 3,
        ProductionRate: 1,
        CurrentEfficiency: 1,
        MissionDuration: 10,
        Yield: 3000,
        ClubEarnings: 15000,
        MyEarnings: 3666,
        DispatchedClubs: 0,
    },
];

export const chooselist = [
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 0.55,
        memberP: 523.06,
        clubP: 3.06,
        count: 534
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        memberP: 523.06,
        clubP: 3.06,
        count: 534
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        memberP: 523.06,
        clubP: 3.06,
        count: 534
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        memberP: 523.06,
        clubP: 3.06,
        count: 534
    },
    {
        avatar: "/images/avatar.png",
        name: "Guest-123123",
        price: 44.89,
        memberP: 523.06,
        clubP: 3.06,
        count: 534
    },
]

export const taskList = [
    {
        title: 'Create Club',
        reward: '+50 $NEXT',
        path: "/start",
        // active: true,
        content: "Receive $NEXT for the first purchase of keys to create your club."
    },
    {
        title: 'Join More Clubs',
        reward: '+50 $NEXT',
        path: "/main",
        // active: true,
        state: { active: 1 },
        content: "Receive $NEXT for the first purchase of keys to join other clubs."
    },
    {
        title: 'Complete PvE Task',
        reward: '+50 $NEXT',
        path: "/main",
        // active: true,
        state: { active: 0 },
        content: "Earn $NEXT for the first participation in PvE tasks and completion."
    },
    {
        title: 'Participate in PvP Battle',
        reward: '+50 $NEXT',
        path: "/main/battle",
        active: true,
        content: "Earn $NEXT for the first raid on another club and victory."
    },
    {
        title: 'Link Your X',
        reward: '+50 $NEXT',
        lock: true,
        content: "Receive $NEXT for linking your NEXTUP account to your X."
    },
    {
        title: 'Complete X Task',
        reward: '+100 $NEXT',
        lock: true,
        content: "Earn $NEXT for the first follow, retweet, and like of NEXTUP official X tweets."
    },
    {
        title: 'Share Achievements',
        reward: '+50 $NEXT',
        lock: true,
        content: "Earn $NEXT for the first sharing of your game achievements on the X platform."
    },
]