import React from "react";
import DataContainer from "./DataContainer";
import { useNavigate } from "react-router-dom";
import { formatNumber, truncateDecimal } from "../utils/tools";


const ClubItem = ({ data, own, tag }) => {
  const navigate = useNavigate()

  const linkToUser = (id) => {
    console.log(id)
    if (id !== own) {
      navigate('/club/' + id)
    }
  }

  return (
    <div className="bg-[#270942] flex mb-3 py-3 px-4 rounded-2xl">
      <div className="pr-2 flex items-center justify-center">
        <div className="w-14 h-14 flex items-center justify-center rounded-full border-[#1cd6d8] 
        overflow-hidden bg-black bg-opacity-50 border-solid border-2"
          onClick={() => linkToUser(tag === 'members' ? data.user.id : data.club_user.id)}>
          <img src={tag === 'members' ? data.user.avatar : data.club_user.avatar} alt="" className="w-14"
            onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
        </div>
      </div>
      <div className="flex-1 py-1 flex flex-col justify-between">
        <div className="flex-1 text-sm flex items-center">
          <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <strong className="text-base">{tag === 'members' ? data.user.name : data.club_user.name}</strong>
          </div>
          <div
            className="h-[22px] w-[22px] flex items-center justify-center text-xs mx-1"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{tag === 'members' ? data.user_club.level : data.club.level}</strong>
          </div>
          {tag === 'members' ?
            (data.user.twitter_name ? (
              <div style={{
                background: "url(/images/twitter.png) no-repeat center center",
                backgroundSize: "100%",
              }}
                className="p-1 text-xs flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
              ></div>
            ) : null) :
            (data.club_user.twitter_name ?
              <div style={{
                background: "url(/images/twitter.png) no-repeat center center",
                backgroundSize: "100%",
              }}
                className="p-1 text-xs flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
              ></div>
              : null)}
        </div>
        {/* <div className="flex-1 text-sm">
          <DataContainer className="flex relative items-center w-32">
            <img src="/images/eth.png" alt="" className="h-[22px] w-[22px] relative left-[-2px]" />
            <div className="text-[#a8a5a9] text-left flex-1">Price</div>
            <div className="pr-2">
              {tag === 'members' ?
                (data.user_club.price > 1 ?
                  formatNumber(data.user_club.price) : truncateDecimal(data.user_club.price, 5))
                :
                (data.club.price > 1 ?
                  formatNumber(data.club.price) : truncateDecimal(data.club.price, 5))}
            </div>
          </DataContainer>
        </div> */}
        <div className="flex w-full">
          <div className="flex flex-col">
            <div className="text-[#8f849b] text-sm mb-[2px]">key Price</div>
            <DataContainer className="flex relative items-center justify-center pl-[20px] text-sm w-28">
              <img src="/images/eth.png" alt="" className='h-[22px] w-[22px] absolute left-[-2px]' />
              <div className="mx-1">
                {tag === 'members' ?
                  (data.user_club.price > 1 ?
                    formatNumber(data.user_club.price) : truncateDecimal(data.user_club.price, 5))
                  :
                  (data.club.price > 1 ?
                    formatNumber(data.club.price) : truncateDecimal(data.club.price, 5))}
              </div>
            </DataContainer>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-end">
        <div className="flex h-5 items-center justify-center">
          <img className="h-5" src="/images/key-white.png" alt="key" />
          <div className="pl-2">
            <strong>x {formatNumber(data.quantity)}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClubItem;
