import React from "react";

const Mark = (props) => {
    return (
        <div
            {...props}
            className={`border-2 flex items-center justify-center rounded-full h-[20px] 
            w-[20px] border-[#808080] ${props.className ? props.className : ''}`}
        >
            <strong className="text-[#808080]">?</strong>
        </div >)

};

export default Mark;




