import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../components/Button";
import WhiteContainer from "../components/WhiteContainer";
import Modal from "../components/Modal"
import Mark from "../components/Mark"
import { post, get } from '../utils/request'
import { formatNumber, truncateDecimal } from '../utils/tools'
import { ToastContainer, toast } from "react-toastify";

const Battle = () => {
    const navigate = useNavigate();
    const rateRef = useRef(null)
    const earnRef = useRef(null)
    const manRef = useRef(null)
    const extractRef = useRef(null)
    const location = useLocation();
    const from = location.state?.from;
    const to = location.state?.to;
    const [amount, setAmount] = useState(1)
    const [rate, setRate] = useState(0)
    const [reward, setReward] = useState(0)
    const [paymax, setPaymax] = useState(0)
    const [base, setBase] = useState(0)
    const [pool, setPool] = useState(0)
    const [clubPool, setClubPool] = useState(0)
    const [ownPool, setOwnPool] = useState(0)
    const [yourKeys, setYourKeys] = useState(0)
    const [totalKeys, setTotalKeys] = useState(0)


    useEffect(() => {
        getPay()
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userInfo) {
            getUserData(userInfo.id)
        }
    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (paymax === 0 && pool > 0) {
                setBase(Math.ceil(pool / 10))
            } else {
                setBase(Math.ceil(paymax / 10))
            }
        }, 300);
        return () => clearTimeout(timer);
    }, [paymax, pool])

    useEffect(() => {
        if (base > 0) {
            let currentRate = (1 - ((to.pool * 0.2) / (base * amount)) / (((to.pool * 0.2) / (base * amount)) + 4))
                * (from.power * 1.5 + 0.01) / (from.power * 1.5 + to.power + 0.02) * 2
            setRate(currentRate)
        }
    }, [amount, base])

    const getUserData = async (id) => {
        let result = await get('/user/' + id)
        let { code, data } = result
        if (code === 1000) {
            setPool(data.pool + data.club.pool)
            setClubPool(data.club.pool)
            setOwnPool(data.pool)
        }
    }

    const getPay = async () => {
        let res = await post('/club/loot/pay', {
            from: from.address, pay: 1, to: to.address
        })
        let { code, data } = res
        if (code === 1000) {
            setReward(data.reward)
            setYourKeys(data.your_keys)
            setTotalKeys(data.total_keys)
            setPaymax(data.pay_max)
        }
    }

    const minusAmount = () => {
        console.log(base)
        if (amount > 1) {
            setAmount(pre => pre - 1)
        }
    }

    const plusAmount = () => {
        if (base * (amount + 1) < pool) {
            setAmount(pre => pre + 1)
        }
    }

    const looting = () => {
        if (from.energy < 5) {
            toast.warning('your energy is not enough!')
        } else if (base * amount > ownPool) {
            openExtractModal()
        } else {
            navigate('/looting', {
                state: {
                    from: from, pay: base * amount, to: to, refund: truncateDecimal(to.pool * 0.2 * 0.5 + reward, 1)
                }
            })
        }
    }

    const openRateModal = () => {
        if (rateRef.current) {
            rateRef.current.togglePopup()
        }
    }

    const openEarnModal = () => {
        if (earnRef.current) {
            earnRef.current.togglePopup()
        }
    }

    const openManModal = () => {
        if (manRef.current) {
            manRef.current.togglePopup()
        }
    }

    const openExtractModal = () => {
        if (extractRef.current) {
            extractRef.current.togglePopup()
        }
    }

    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-gradient-to-b from-[#4e0970] to-[#160829]" >
            <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
            <div className="w-full flex-none relative">
                <img src="/images/battle-now.png" alt="" className="w-full h-auto" />
                <div className="absolute top-0 left-0 text-white w-full text-lg py-4">
                    <div className="relative flex items-center justify-center">
                        <strong>BATTLE</strong>
                        <div className="absolute left-4" onClick={() => navigate(-1)}>
                            <img className="w-3" src="/images/back.png" alt="back" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-4 overflow-y-auto fixed bottom-6 max-w-[768px] w-full" >
                <div className="w-full flex justify-around px-4 pb-6">
                    <div className="flex flex-col items-center slide-in-from-left opacity-0">
                        <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                            <img src={from.owner.avatar ? from.owner.avatar : "/images/avatar.png"} alt=""
                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                        </div>
                        <div className="w-20 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs mt-1 text-center">
                            {from.owner.name}
                        </div>
                        <div className="text-[10px] text-[#808081] my-1">Power</div>
                        <div className="flex flex-row items-center">
                            <strong className="text-[#6573f6] text-xl text-center max-w-24 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {formatNumber(from.power)}
                            </strong>
                            <Mark className='ml-2' onClick={openManModal} />
                        </div>
                    </div>
                    <img src="/images/vs.png" alt="" className="h-24 w-24 mt-4" />
                    {/* <div className="h-24 w-24" /> */}
                    <div className="flex flex-col items-center slide-in-from-right opacity-0">
                        <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                            <img src={to.owner.avatar ? to.owner.avatar : "/images/avatar.png"} alt=""
                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                        </div>
                        <div className="w-20 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs mt-1 text-center">
                            {to.owner.name}</div>
                        <div className="text-[10px] text-[#808081] my-1">Power</div>
                        <div className="flex flex-row items-center">
                            <strong className="text-[#6573f6] text-xl text-center max-w-24 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {formatNumber(to.power)}
                            </strong>
                            <Mark className='ml-2' onClick={openManModal} />
                        </div>
                    </div>
                </div>
                <div className="rounded-full h-12 flex flex-row justify-center items-center bg-gradient-to-r from-[#471874] to-[#721a72]">
                    <strong>Success Rate:</strong><strong className="ml-2" style={{ color: "#6573f6" }}>
                        {rate < 0.9 ? (rate * 100).toFixed(2) : 90}%
                    </strong>
                    <Mark className='ml-2' onClick={openRateModal} />
                </div>
                <div className="mt-4 text-xs text-center mb-4 text-[#808080]">Enter Loot Fee to Improve Success Rate</div>
                <div className="rounded-full h-12 flex flex-row justify-between items-center border border-1 
                text-sm px-4 border-[#6d5581] bg-[#26083e]" >
                    {/* <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-2" /> */}
                    <div className={`flex items-center justify-center rounded-full h-[24px] w-[24px] px-1 
                            ${amount > 1 ? "bg-white" : "bg-[#665378]"}`}
                        onClick={minusAmount}>
                        {amount > 1 ? <img src="/images/black-minus.png" alt="" style={{ height: 20 }} /> :
                            <img src="/images/fog-minus.png" alt="" style={{ height: 20 }} />}
                    </div>
                    <div className="w-20 flex flex-row justify-center items-center text-base">
                        <img className="h-5 pr-2" src="/images/ttt.png" alt="key" />
                        {truncateDecimal(amount * base, 1)}
                        {/* <EditableDiv data={amount} backValue={setAmount}>
                            <img className="h-5" src="/images/ttt.png" alt="key" />
                            <div className="h-5 pl-2 text-lg flex items-center">x {!isNaN(amount) ? amount : ''}</div>
                        </EditableDiv> */}
                    </div>
                    <div className={`${base * (amount + 1) < pool ? "bg-white" : "bg-[#665378]"} 
                    flex items-center justify-center rounded-full h-[24px] w-[24px] px-1`}
                        onClick={plusAmount}>
                        {base * (amount + 1) < pool ? <img src="/images/black-plus.png" alt="" /> :
                            <img src="/images/fog-plus.png" alt="" />}
                    </div>
                </div>
                <div className="flex justify-between mt-2">
                    <div className="text-xs" style={{ color: "#808080" }}>
                        Min Fee: <span style={{ color: "#816101" }}>
                            {base}</span></div>
                    <div className="text-xs" style={{ color: "#808080" }}>
                        Total Avaliable $NEXT: <span style={{ color: "#816101" }}>
                            {truncateDecimal(pool, 1)}</span></div>
                </div>

                <div className="my-3 h-48 rounded-xl flex flex-col" style={{ background: "#1f0836" }}>
                    <div className="flex flex-[3] flex-col px-4 pt-2">
                        <div className="flex flex-1 justify-between items-center text-sm">
                            <div style={{ color: "#808080" }}>$NEXT Pool:</div>
                            <div className="flex flex-row">
                                <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                                {truncateDecimal(to.pool, 1)}
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between items-center text-sm">
                            <div style={{ color: "#808080" }}>In-Progress Earnings:</div>
                            <div className="flex flex-row">
                                <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                                0
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between items-center text-sm ">
                            <div style={{ color: "#808080" }}>Loot Rate:</div>
                            <div className="flex flex-row">
                                20%
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-[2] flex-col px-4 pb-2 bg-[#130625] rounded-b-xl">
                        <div className="flex flex-1 justify-between items-center text-sm">
                            <div style={{ color: "#808080" }}>Expected Returns:</div>
                            <div className="flex flex-row">
                                <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                                {truncateDecimal(to.pool * 0.2, 1)}
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between items-center text-sm">
                            <div style={{ color: "#808080" }}>My Earnings:</div>
                            <div className="flex flex-row">
                                <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                                {truncateDecimal(to.pool * 0.2 * 0.5 + reward, 1)}
                                <Mark className='ml-2' onClick={openEarnModal} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="h-12">
                    <Button className={`w-full h-full flex text-sm rounded-full items-center justify-center bg-gradient-to-r from-[#684dfc] to-[#fc33b6]`}
                        onClick={looting}
                        disabled={isNaN(amount) || amount < 1 || base * amount === 0}>
                        <strong>LOOT NOW</strong>
                        <img src="/images/energy-orange.png" alt="energy"
                            className="w-[20px] h-[20px] ml-2 mr-1" />
                        <strong>-5</strong>
                    </Button>
                </div>
            </div>

            <Modal ref={rateRef} closeIcon={false}>
                <div className="flex flex-col items-center my-4 w-full">
                    <p>The loot success rate is determined by the power factor. A higher loot fee increases the success rate odds.</p>
                    <p className="w-full text-[#fcb833]">F = Loot fee paid</p>
                    <p className="w-full text-[#fcb833]">R = Expected returns</p>
                    <p className="w-full text-[#fcb833]">A = Attacker's power</p>
                    <p className="w-full text-[#fcb833]">D = Defender's power</p>
                    <p className="w-full text-[#fcb833]">ΔLv = max(0,min(Defender's Level − Attacker's Level,7))</p>
                    <p className="w-full text-[#fcb833]">Success rate = (1-</p>
                    <p className="w-full break-all text-[#fcb833]">(R/(F/(1+0.1*ΔLv^2))/((R/(F/(1+0.1*ΔLv^2))+4)))* (A*1.5+0.01)/(A*1.5+D+0.02) * 100%</p>
                    <p className="w-full">Increasing loot fee can lead to maximum success rate limit of 90%.</p>
                </div>
            </Modal>


            <Modal ref={manRef} closeIcon={false}>
                <div className="flex flex-col items-center my-4 w-full">
                    <div className="mb-2">Power is determined by the total value of all Members and Joined.</div>
                    <p className="mb-1 text-[#fcb833] w-full"> X = Total value of all Members</p>
                    <div className="mb-1 text-[#fcb833] w-full"> Y = Total value of all Joined</div>
                    <div className="mb-1 text-[#fcb833] w-full"> (For Wish accounts, Y = current Key Price)</div >
                    <div className="mb-1 text-[#fcb833] w-full"> N = Number of Club Keys are generated and bought</div >
                    <div className="mb-1 text-[#fcb833] w-full"> T = min(X * min(N/50,1),Y*10)+ Y</div>
                    <div className="mb-1 w-full">Club value(T)</div>
                    <div className="mb-1 text-[#fcb833] w-full">Power = Int(SQRT(50*T)*7))</div>
                </div >
            </Modal >

            <Modal ref={earnRef} closeIcon={false} title="EARNINGS DETAIL">
                <div className="flex flex-col items-center my-4 w-full text-sm">
                    <div className="w-full text-left text-base">
                        {/* The total club earnings will split 40% to the manager, 40% among all members and 20% to the club owner. */}
                        The total club earnings will split 50% to the manager and 50% among all members.
                    </div>
                    <div className="flex flex-col w-full my-1">
                        <div className="flex justify-between w-full my-1">
                            <div>Total Club Earnings</div>
                            <div className="flex items-center">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {truncateDecimal(to.pool * 0.2, 1)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1">
                            <div className="flex flex-[2]">Manager's Share</div>
                            <div className="flex flex-1 justify-center items-center">50%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {truncateDecimal(to.pool * 0.2 * 0.5, 1)}
                            </div>
                        </div>
                        {/* <div className="flex justify-between w-full my-1">
                            <div className="flex flex-[2]">Club Owner's Shares</div>
                            <div className="flex flex-1 justify-center items-center">40%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />-
                            </div>
                        </div> */}
                        <div className="flex justify-between w-full my-1 items-center">
                            <div className="flex flex-col flex-[2]">
                                <div>Key Holders Share</div>
                                <div>You Hold {yourKeys}/{totalKeys} Keys</div>
                            </div>
                            <div className="flex flex-1 justify-center items-center">
                                {totalKeys === 0 ? 0 : formatNumber(yourKeys / totalKeys * 50)}%
                            </div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {totalKeys === 0 ? 0 : truncateDecimal(to.pool * 0.2 * 0.5 * (yourKeys / totalKeys), 1)}
                            </div>
                        </div>
                        <div className="border-[#ccc] border-t mt-2"></div>

                        <div className="flex justify-between w-full my-2">
                            <div className="flex flex-[2]">My Earnings</div>
                            <div className="flex flex-1 justify-center items-center">
                                {formatNumber(50 * (yourKeys / totalKeys + 1))} %</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {truncateDecimal(to.pool * 0.2 * 0.5 + reward, 1)}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal ref={extractRef} title="INSUFFICIENT LOOT FEE" closeIcon={false}>
                <div className="flex flex-col my-4 w-full text-xs">
                    <div className="w-full text-sm my-1">you need $NEXT to cover the required fee,</div>
                    <div className="w-full text-sm my-1">would you to extract it now from your $NEXT pool?</div>
                    <div className="flex flex-col text-sm mt-2">
                        <div>Your $NEXT Pool</div>
                        <WhiteContainer className='w-32 flex flex-row mt-1'>
                            <img src="/images/ttt.png" alt="" className='h-[20px] w-[20px] relative top-[4px] left-[4px]' />
                            <div className="pl-[8px]">
                                {clubPool < 1 ? truncateDecimal(clubPool, 3) : formatNumber(clubPool)}</div>
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-col text-sm my-2">
                        <div>Extra $NEXT Needed</div>
                        <WhiteContainer className='w-32 flex flex-row mt-1'>
                            <img src="/images/ttt.png" alt="" className='h-[20px] w-[20px] relative top-[4px] left-[4px]' />
                            <div className="pl-[8px]">
                                {amount * base - ownPool < 1 ? truncateDecimal((amount * base - ownPool), 3)
                                    : formatNumber(amount * base - ownPool)}</div>
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-row mt-2 w-full justify-between">
                        <Button className='flex flex-1 mr-2 items-center h-8 justify-center' onClick={() => {
                            navigate('/looting', {
                                state: {
                                    from: from, pay: base * amount, to: to, refund: truncateDecimal(to.pool * 0.2 * 0.5 + reward, 1)
                                }
                            })
                        }}>
                            <strong>EXTRACT</strong>
                        </Button>
                        <Button className='flex flex-1 ml-2 items-center h-8 bg-white justify-center'
                            onClick={openExtractModal}>
                            <strong className="text-black">LATER</strong>
                        </Button>
                    </div>

                </div>
            </Modal>

        </div >
    );
};

export default Battle;
