import React from "react";

const WishItem = ({ data }) => {

    const getTrend = (trend) => {
        let precent = Math.abs(trend) * 100 + "%";
        if (trend >= 0) {
            return (
                <div className="text-[#3fe51c] rounded-full text-right bg-[#2d7e1f] bg-opacity-50 h-[20px] text-[14px] px-1 green-arrow">
                    {precent}
                </div>
            );
        } else {
            return (
                <div className="text-[#ff6203] rounded-full text-right bg-[#88332c] bg-opacity-50 h-[20px] text-[14px] px-1 red-arrow">
                    {precent}
                </div>
            );
        }
    };
    return (
        <div className="bg-[#a6a1bb] flex bg-opacity-20 mb-4 py-2 rounded-lg">
            <div className="px-4 flex items-center justify-center">
                <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                    <img src={data.avatar} alt="avatar" />
                </div>
            </div>
            <div className="flex-1 py-2 flex flex-col">
                <div className="flex-1 text-sm flex">
                    <div>{data.name}</div>
                </div>
                <div className="flex flex-1 py-1 text-xs text-gray-500 flex-row items-center">
                    <div className="text-[#949497]">{data.id}</div>
                    <div
                        style={{
                            background: "url(/images/twitter.png) no-repeat center center",
                            backgroundSize: "100%",
                        }}
                        className="p-1 mx-1 text-xs flex items-center rounded-full m-[1px] h-[12px] w-[12px] justify-center"
                    ></div>
                </div>
                <div className="flex-1">
                    <div className="text-left flex-1  text-sm ">Members {data.count}</div>
                </div>
            </div>
            <div className="min-w-28 px-4 flex items-end justify-center flex-col">
                <div className="flex h-5 items-center justify-center">
                    <strong>{data.price} $BNB</strong>
                </div>
                <div className="flex h-5 items-center justify-center flex-row">
                    <div className="mt-1 flex flex-1 justify-end">{getTrend(data.trend)}</div>
                </div>
            </div>
        </div>
    );
};
export default WishItem;
