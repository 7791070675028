import React, { useEffect, useRef, userRef, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import contractABI from "../abi/jumpup";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button"
import EditableDiv from "../components/EditableDiv"
import Modal from "../components/Modal"
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from "ethers";
import { useWallets } from "@privy-io/react-auth";
import { REACT_APP_CONTRACT } from "../config"
import { post } from "../utils/request"
import { truncateDecimal } from "../utils/tools"


const Init = () => {
    const navigate = useNavigate()
    const [address, setAddress] = useState('')
    const [balance, setBalance] = useState(0)
    const [syncing, setSyncing] = useState(false);
    const [buyAmount, setBuyAmount] = useState(5)
    const [isBuying, setIsBuying] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [buyEth, setBuyEth] = useState(0)
    const [totalBuyEth, setTotalBuyEth] = useState(0)
    const [buyVisible, setBuyVisible] = useState(false)

    const modalRef = useRef(null)
    const { wallets } = useWallets();
    const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
    );

    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userInfo) {
            if (!userInfo.invite_user || userInfo.invite_user.id === '') {
                navigate('/invite', { replace: true })
            } else {
                setAddress(userInfo.club.address)
            }
        }
    }, [])

    useEffect(() => {
        if (!buyVisible) {
            setBuyAmount(5)
            setBuyEth(0)
            setTotalBuyEth(0)
        } else {
            setBuyInfo()
        }
    }, [buyVisible])

    useEffect(() => {
        if (!isNaN(buyAmount)) {
            setBuyInfo()
        }
    }, [buyAmount])

    useEffect(() => {
        getBalance()
    }, [embeddedWallet])

    const handleCopy = () => {
        navigator.clipboard.writeText(address);
        toast.success('copied!')
    };

    const getBalance = async () => {
        setSyncing(true)
        try {
            if (typeof embeddedWallet !== "undefined") {
                const provider = await embeddedWallet?.getEthereumProvider();
                const balance = await provider?.request({
                    method: 'eth_getBalance',
                    params: [embeddedWallet?.address, 'latest'], // 'latest' 表示最新的区块
                });
                const balanceInEth = ethers.formatEther(balance);
                setBalance(parseFloat(balanceInEth))
            }
        } catch (error) {
        } finally {
            setSyncing(false)
        }
    }

    const openModal = () => {
        if (modalRef.current) {
            modalRef.current.togglePopup()
        }
    }

    const minusBuyAmount = () => {
        if (buyAmount > 1) {
            setBuyAmount(pre => pre - 1)
        }
    }

    const plusBuyAmount = () => {
        setBuyAmount(pre => pre + 1)
    }

    const setBuyInfo = async () => {
        try {
            const provider = await embeddedWallet.getEthersProvider();
            const signer = provider?.getSigner();
            const contract = new ethers.Contract(
                REACT_APP_CONTRACT,
                contractABI, signer
            );
            console.log('contract:' + REACT_APP_CONTRACT)
            console.log('address:' + address)
            console.log('buyAmount:' + buyAmount)
            const balance = await contract.getBalanceOf(
                // eslint-disable-next-line no-undef
                '0x7534789CdbC0E6ec2cE304972cb1Ee45CbBfb461', '0x7534789CdbC0E6ec2cE304972cb1Ee45CbBfb461'
            );
            console.log('balance=' + balance)
            const price = await contract.getBuyPrice(
                // eslint-disable-next-line no-undef
                address, BigInt(buyAmount)
            );
            const totalPrice = await contract.getBuyPriceAfterFee(
                // eslint-disable-next-line no-undef
                address, BigInt(buyAmount)
            );
            const buyBalanceInEth = ethers.formatEther(price);
            const buyTotalEth = ethers.formatEther(totalPrice);
            console.log(buyTotalEth)
            setBuyEth(buyBalanceInEth)
            setTotalBuyEth(buyTotalEth)
        } catch (error) {

        }
    }


    const buy = async () => {
        try {
            setIsBuying(true);
            const provider = await embeddedWallet.getEthersProvider();
            const signer = provider?.getSigner();
            const contract = new ethers.Contract(
                REACT_APP_CONTRACT,
                contractABI, signer
            );
            const price = await contract.getBuyPriceAfterFee(
                // eslint-disable-next-line no-undef 
                address, BigInt(buyAmount)
            );
            console.log('contract:' + REACT_APP_CONTRACT)
            console.log('address:' + address)
            console.log('walletAddress:' + embeddedWallet?.address)
            console.log('buyAmount:' + buyAmount)
            console.log("price:" + price)
            const txResponse = await contract.buyShares(
                // eslint-disable-next-line no-undef 
                address, BigInt(buyAmount), { from: embeddedWallet?.address, value: price }
            );
            console.log("Transaction sent:", txResponse.hash);
            // await txResponse.wait();
            let postData = {
                club_address: address,
                key_num: buyAmount,
                key_price: -(totalBuyEth),
                transaction: txResponse.hash,
            };
            let result = await post("/trade/buy", postData);
            let { code, data, msg } = result;
            if (code === 1000) {
                modalRef.current.togglePopup()
                navigate('/main')
                //访问成功
            } else {
                toast.error(msg)
            }
        } catch (error) {
            toast.warning('transaction failed')
            modalRef.current.togglePopup()
        } finally {
            setIsBuying(false);
        }
    };


    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat bg-center"
            style={{ backgroundImage: "url(/images/mission.png)" }}
        >
            <ToastContainer pauseOnFocusLoss={false} ntainer theme="dark" position="top-center" />
            <div className="flex flex-col fixed bottom-0 w-full max-w-[768px]">
                <div className="flex px-10 text-xs justify-center text-center">
                    <strong>Deposit at least 0.01 $BNB to your wallet and create your club</strong>
                </div>
                <div className="p-4 m-4 border rounded-md border-[#545454] text-xs flex flex-col">
                    <div className="w-full text-center">
                        Deposit to Your NEXTUP Wallet Address Below
                    </div>
                    <div className="text-sm flex flex-row mt-2 items-center justify-center relative px-[25px]">
                        <div className="flex flex-1 break-all text-center justify-center">
                            <strong>{address}</strong>
                        </div>
                        <img
                            src="/images/copy.png"
                            alt=""
                            style={{
                                height: 14,
                                width: 14,
                                marginLeft: 10,
                                cursor: "pointer",
                            }}
                            className="absolute right-[5px]"
                            onClick={handleCopy}
                        />
                    </div>
                </div>
                <div className="rounded-3xl flex flex-col bg-black border-t-[#545454] border-t pb-6">
                    <div className="py-3 m-4 rounded-md border border-[#545454] text-xs flex flex-col 
                    justify-center items-center relative">
                        <div className="text-xl mb-1">
                            {balance} $BNB
                        </div>
                        <div className="text-[12px] text-gray-400">
                            My Wallet Balance
                        </div>
                        <div className="absolute right-[10px] top-[0px] h-full flex items-center"
                            onClick={getBalance}>
                            {syncing ? <img
                                src="/icon/refresh.png"
                                alt="refresh"
                                style={{
                                    animation: "rotate 2s linear infinite",
                                }}
                                className="h-5 w-5"
                            /> :
                                <img
                                    src="/icon/refresh.png"
                                    alt="refresh"
                                    className="h-5 w-5"
                                />}
                        </div>

                    </div>
                    <Button className="mx-4 py-3 text-base text-white mb-2 mt-0 bg-gradient-to-r from-[#684dfc] to-[#fc33b6]"
                        onClick={openModal}
                        style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <strong>CREATE CLUB TO EARN $NEXT</strong>
                    </Button>
                    <Button className="mx-4 py-3 text-base text-white my-2"
                        style={{ background: "#7d7d7d" }}
                        onClick={() => { navigate('/main') }}>
                        <strong> MAYBE LATER</strong>
                    </Button>
                </div>

            </div>

            <Modal ref={modalRef} title='Buy Keys' backVisible={setBuyVisible}>
                <div className="flex flex-col items-center mt-4 mb-2">
                    <div className="w-full flex flex-row justify-between items-center">
                        <div className="flex text-xs items-center">
                            You Own
                            <img className="h-3 ml-2" src="/images/key-white.png" alt="key"
                            />{" "}
                            <div className="pl-2">x 0</div>
                        </div>
                        <div className="px-4 bg-[#322435] h-5 flex items-center justify-around rounded-full">
                            <div className="text-xs"> {balance.toFixed(7)} $BNB</div>
                        </div>
                    </div>
                    <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2" >
                        <img src='/images/avatar.png' alt="avatar" className="w-16 h-16" />
                    </div>
                    <div className="text-xs my-2">Create Your Club</div>
                    <div
                        className="w-full py-2 my-4 rounded-full"
                        style={{ border: "1px solid #3f3e41" }}
                    >
                        <div className="flex items-center justify-between px-3">
                            <div className="border flex items-center justify-center rounded-full h-[24px] w-[24px] px-1"
                                onClick={minusBuyAmount}>
                                <img src="/images/minus.png" alt="" />
                            </div>
                            <div className="w-20 flex flex-row justify-center">
                                <EditableDiv data={buyAmount} backValue={setBuyAmount}>
                                    <img className="h-5" src="/images/key-white.png" alt="key" />
                                    <div className="pl-2">x {!isNaN(buyAmount) ? buyAmount : ''}</div>
                                </EditableDiv>
                            </div>
                            <div className="border flex items-center justify-center rounded-full h-[24px] w-[24px] px-1"
                                onClick={plusBuyAmount}>
                                <img src="/images/plus.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div
                        className="w-full py-4 px-4 rounded-xl flex flex-col"
                        style={{ border: "1px solid #3f3e41" }}
                    >
                        <div className="flex flex-row justify-between">
                            <div className="text-[#878788]">Total(inc.fees)</div>
                            <div className="flex flex-col">
                                <div className="max-w-32 break-all text-right">
                                    {truncateDecimal(parseFloat(totalBuyEth), 7)} $BNB</div>
                                {/* <div className="text-[#878788] w-32 break-all text-right">
                                        USD $ {totalBuyEth * rate}</div> */}
                            </div>
                        </div>
                        {!isOpen ? <div className="flex justify-center mt-2" onClick={() => { setIsOpen(!isOpen) }}>
                            <img src="/images/arrow-bottom.png" alt="" className="h-2" />
                        </div> : null}
                        {isOpen ?
                            <div className="border-t border-[#3f3e41] mt-5 flex flex-col">
                                <div className="flex flex-row justify-between text-sm my-2 items-center">
                                    <div className="text-[#878788]">From</div>
                                    <div className="flex flex-row items-center">
                                        <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                                            {address}
                                        </div>
                                        <img src="/images/copy.png" alt="" className="w-[12px] h-[12px]" onClick={handleCopy} />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between text-sm my-2 items-center">
                                    <div className="text-[#878788]">To</div>
                                    <div className="flex flex-row items-center">
                                        <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                                            {address}
                                        </div>
                                        <img src="/images/copy.png" alt="" className="w-[12px] h-[12px]" onClick={handleCopy} />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between text-sm my-2 items-center">
                                    <div className="text-[#878788]">Key</div>
                                    <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                                        {truncateDecimal(parseFloat(buyEth), 10)} $BNB
                                        {/* {buyEth} $BNB */}
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between text-sm my-2 items-center">
                                    <div className="text-[#878788]">Fees</div>
                                    <div className="max-w-40 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                                        {truncateDecimal(parseFloat(totalBuyEth - buyEth), 10)} $BNB
                                    </div>
                                </div>
                                <div className="flex justify-center my-2" onClick={() => { setIsOpen(!isOpen) }}>
                                    <img src="/images/arrow-bottom.png" alt="" className="h-2" style={{ transform: 'rotateX(180deg)' }} />
                                </div>
                            </div>
                            : null}
                    </div>
                    <Button
                        className="h-10 text-black flex items-center mt-4 justify-center text-sm flex-none"
                        onClick={buy}
                        disabled={isBuying || isNaN(buyAmount) || buyAmount < 1 || balance < totalBuyEth}
                    >
                        <strong>BUY NOW</strong>
                    </Button>
                </div>
            </Modal>

        </div >
    )

};

export default Init;
