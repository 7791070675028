import React from 'react';

const TickCrossIcon = ({ value }) => {
    const renderIcon = () => {
        if (value === true) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="100%" height="100%">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                </svg>
            );
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="100%" height="100%">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                </svg>
            );
        }
    };

    return (
        <div style={{ width: '50px', height: '50px', borderRadius: '50%', border: '1px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderIcon()}
        </div>
    );
};

export default TickCrossIcon;
