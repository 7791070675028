import React from "react";
// start #ec3468
// end #410483
const Button = (props) => {
  return (
    <button
      {...props}
      className={`px-8 bg-[#6450fd] text-center text-white rounded-full ${props.className ? props.className : ""}`}
    //bg-button-primary bg-[#fcb833] 
    >
      {props.children}
    </button>
  );
};

export default Button;
