import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal"
import { ToastContainer, toast } from "react-toastify";
import { formatNumber, truncateDecimal } from "../utils/tools"
import { get } from "../utils/request"


const Group = () => {
    const navigate = useNavigate();
    const ref = useRef(null)
    const twitterRef = useRef(null)
    const scrollRef = useRef(null)
    const [content, setContent] = useState('')
    const [invite, setInvite] = useState(0)
    const [current, setCurrent] = useState(0)
    const [list, setList] = useState([])
    const [next, setNext] = useState(0)
    const [count, setCount] = useState(0)
    const [user, setUser] = useState({ name: '', booster: 0 })
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [isScrolledToNewBottom, setIsScrolledToNewBottom] = useState(false);

    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userInfo !== null) {
            setUser(userInfo)
        }
        // getInvite()
        getBooster()
        getGroupList()
        getTotalValue()
    }, [])

    useEffect(() => {
        const scrollableDiv = scrollRef.current;
        const handleScroll = () => {
            const scrollTop = scrollableDiv.scrollTop;
            const scrollHeight = scrollableDiv.scrollHeight;
            const clientHeight = scrollableDiv.clientHeight;

            if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
                if (!isScrolledToNewBottom) {
                    setPage(prePage => (prePage + 1))
                    console.log('滚动到了底部');
                    setIsScrolledToNewBottom(true);
                }
            } else {
                setIsScrolledToNewBottom(false);
            }
        };
        scrollableDiv.addEventListener('scroll', handleScroll);
        return () => {
            scrollableDiv.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        getGroupList()
    }, [page])

    const getGroupList = async () => {
        if (page === 1) {
            let result = await get(`/club/group?page=${page}&size=${size}`)
            let { code, data } = result
            if (code === 1000) {
                let { total, list } = data
                setList(list)
                setTotal(total)
            }
        } else if ((page - 1) * size < total) {
            let result = await get(`/club/group?page=${page}&size=${size}`)
            let { code, data } = result
            if (code === 1000) {
                let { total, list: otherList } = data
                let tempList = [...list]
                let currentList = tempList.concat(otherList)
                setList(currentList)
                setTotal(total)
            }
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText('nextup.gg/share/@' + (user.twitter_name ? user.twitter_name : user.name)).then(() =>
            toast.success('copied!')
        )
    }

    const handleCodeCopy = () => {
        navigator.clipboard.writeText(user.invite_code).then(() =>
            toast.success('copied!')
        )
    }

    const getInvite = async () => {
        let res = await get("/user/invite/count");
        if (res.code === 1000) {
            setInvite(res.data);
        }
    }

    const getTotalValue = async () => {
        let res = await get("/club/group/value");
        if (res.code === 1000) {
            setTotalValue(res.data);
        }
    }

    const getBooster = async () => {
        let res = await get("/club/booster");
        if (res.code === 1000) {
            setCurrent(res.data.current)
            setNext(res.data.next)
            setCount(res.data.count)
        }
    }

    const rocketClick = () => {
        if (ref.current) {
            ref.current.togglePopup()
        }
        setContent('Use your referral code to invite players. Your group portfolio value unlocks $NEXT multipliers which amplifies your earnings and yield.')
    }

    const personClick = () => {
        if (ref.current) {
            ref.current.togglePopup()
        }
        setContent('For every invitee that joins using your referral code, you get an extra life for the Daily Trivia game. This could potentially increase you chances of winning daily rewards.')
    }

    const openShareModal = () => {
        if (twitterRef.current) {
            twitterRef.current.togglePopup();
        }
    }

    return (
        <div className="w-full h-full flex flex-col max-w-[768px] bg-cover bg-no-repeat bg-center overflow-hidden
        bg-gradient-to-b from-[#470d6e] to-[#2e0a4a]"
        // style={{ backgroundImage: "url(/images/group.png)" }}
        >
            <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
            <div className="h-20 p-4 flex items-start pt-5 flex-none">
                <div className="w-4 cursor-pointer mr-2 mt-1" onClick={() => navigate(-1)}>
                    <img className="w-3" src="/images/back.png" alt="back" />
                </div>
                <div className="w-3"></div>
                <div className="flex flex-1 text-2xl text-center text-white">
                    <strong>Bring Friends to NEXTUP and Reap Rewards!</strong>
                </div>
            </div>
            <div className="w-full text-center mt-5 mb-2 flex-none text-[#9f86b2] text-sm"><strong>MY GROUP</strong></div>
            <div className="w-full flex flex-grow flex-col overflow-hidden">
                <div className="flex flex-1 overflow-y-auto flex-col pb-2" ref={scrollRef}>
                    {list.map((item, index) => (
                        <div className="flex flex-row px-4 py-0.5" key={index}>
                            <div className="flex bg-[#23073a] flex-1 my-0.5 rounded-xl h-14 items-center px-4 relative">
                                <div className="w-8 h-8 flex items-center justify-center rounded-full border-white overflow-hidden 
                                bg-black bg-opacity-50 border-solid border-2"  onClick={() => { navigate('/chat/' + item.id) }}>
                                    <img src={item.avatar} alt=""
                                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                                </div>
                                <div className="text-sm ml-2 flex flex-col">
                                    <div>{item.name}</div>
                                    {item.twitter_name ?
                                        <div className="text-xs text-[#969598] flex flex-row flex-1">
                                            {item.twitter_name}
                                            <div
                                                style={{
                                                    background: "url(/images/twitter.png) no-repeat center center",
                                                    backgroundSize: "100%",
                                                }}
                                                className="p-1 mx-1 text-xs flex items-center rounded-full m-[1px] h-[12px] w-[12px] justify-center"
                                            ></div>
                                        </div> : null}
                                </div>
                                <div className="text-sm absolute right-[1rem] flex flex-row">
                                    <img src="/images/eth.png" alt="" className="h-[20px] w-[20px] mr-2" />
                                    <strong>{item.club && item.club.value ?
                                        truncateDecimal(item.club.value, 6)
                                        : 0}</strong>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-none bg-[#0e0520] pt-4 flex-col pb-6 w-full"
                    style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div className="flex flex-row px-4 py-1 w-full">
                        <div className="flex bg-[#411e60] flex-1 rounded-xl items-center px-3 relative py-1">
                            <div className="text-sm ml-2 flex flex-col pr-[120px]">
                                <div>$NEXT Booster</div>
                                <div className="text-[12px] text-[#929194] flex flex-row flex-1">
                                    Get more multipliers as your group grows
                                </div>
                                <div className="text-[12px] text-[#929194] flex flex-row flex-1 items-center">
                                    Total Group Portfolio:
                                    <img src="/images/eth.png" alt="" className="h-[14px] w-[14px] mx-2 text-white" />
                                    {truncateDecimal(totalValue, 6)}
                                </div>
                                <div className="text-[12px] text-[#929194] flex flex-row flex-1 items-center">
                                    Next Multiplier at:
                                    <img src="/images/eth.png" alt="" className="h-[14px] w-[14px] mx-2" />
                                    {next}
                                </div>
                            </div>
                            <div className="w-28 text-sm absolute right-[0] flex flex-row items-center">
                                <div className="bg-button-up p-2 flex items-center rounded-full h-[30px] w-[30px] mr-2">
                                    <img src="/images/rocket.png" alt="" />
                                </div>
                                <strong>X {formatNumber(count)}</strong>
                                <div className="ml-3 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#929194]"
                                    onClick={rocketClick}>
                                    <strong className="text-[#929194]">?</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full text-[#929194] text-xs text-center my-3">
                        My Referral Code
                    </div>
                    <button className="border mx-4 rounded-full flex justify-center mb-4 text-sm py-2 items-center"
                        onClick={handleCodeCopy} >
                        <strong>{user.invite_code}</strong>
                        <img src="/images/copy.png" alt="" style={{ height: 14, width: 14, marginLeft: 10 }} />
                    </button>
                    <div className="w-full text-[#929194] text-xs text-center mb-3">
                        My Referral Link
                    </div>
                    <button className="border mx-4 rounded-full flex justify-center mb-4 text-sm py-2 items-center"
                        onClick={handleCopy} >
                        <strong>{'nextup.gg/share/@' + (user.twitter_name ? user.twitter_name : user.name)}</strong>
                        <img src="/images/copy.png" alt="" style={{ height: 14, width: 14, marginLeft: 10 }} />
                    </button>

                    <button className={`flex text-xs py-3 bg-[#6450fd] text-center text-white rounded-full items-center justify-center mx-4 mb-2`}
                        onClick={openShareModal} disabled={user.twitter_name == ""}>
                        <img src="/images/twitter-white.png" alt="" style={{ height: 14, width: 14, marginRight: 10 }} />
                        <strong>SHARE ON X</strong>
                    </button>
                </div>

            </div>

            <Modal ref={ref} closeIcon={false}>
                <div className='w-full break-all flex justify-around my-4 flex-col flex-1 items-center'>
                    {content}
                </div>
            </Modal>

            <Modal ref={twitterRef} title="Share Your Referral Code Through X(Twitter)">
                <div className="w-full break-all flex justify-start my-4 flex-col flex-1 items-center bg-gray-800 p-2 rounded-md">
                    Playing missions and battles, looting #CryptoTrillionaires on @NextUpBSC!<br />
                    Use my code to start:<br />
                    http://www.nextup.gg/share/@{user.twitter_name}<br />
                    Ready to #NEXTUP? Let's play!  #SocialGaming
                </div>
                <div className="flex justify-center">
                    <a
                        className="flex-1 h-12 max-w-[200px] flex justify-center items-center px-8 bg-[#6450fd] text-center text-white rounded-full"
                        href={`https://twitter.com/intent/tweet?text=Playing%20missions%20and%20battles%2C%20looting%20%23CryptoTrillionaires%20on%20%40NextUpBSC!%20Use%20my%20code%20to%20start%3A%20http%3A%2F%2Fwww.nextup.gg%2Fshare%2F%40${user.twitter_name}%20Ready%20to%20%23NEXTUP%3F%20Let%27s%20play!%20%23SocialGaming`} target="_blank">
                        <img className="w-4 h-4 mr-2" src="/images/twitter-white.png" alt="x" /><strong>POST</strong>
                    </a>
                </div>
            </Modal>

        </div>
    );
};

export default Group;



// const list = [
//     {
//         avatar: "/images/avatar.png",
//         name: "Guest-123123",
//         price: 0.55,
//         id: '@BitCloutCat',
//     },
//     {
//         avatar: "/images/avatar.png",
//         name: "Guest-123123",
//         price: 44.89,
//         id: '@BitCloutCat',
//     },
//     {
//         avatar: "/images/avatar.png",
//         name: "Guest-123123",
//         price: 44.89,
//         id: '@BitCloutCat',
//     },
//     {
//         avatar: "/images/avatar.png",
//         name: "Guest-123123",
//         price: 44.89,
//         id: '@BitCloutCat',
//     },
//     {
//         avatar: "/images/avatar.png",
//         name: "Guest-123123",
//         price: 44.89,
//     },
// ]