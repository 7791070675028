import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useSpring, animated, AnimationConfig } from 'react-spring';


const Modal = forwardRef(({ ...props }, ref) => {
    useImperativeHandle(ref, () => ({
        togglePopup
    }))
    const [showPopup, setShowPopup] = useState(false);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        getShowPopupheight()
        if (props.backVisible) {
            props.backVisible(showPopup)
        }
    }, [showPopup, height])

    const popupAnimation = useSpring({
        config: { duration: 0 },
        opacity: showPopup ? 1 : 0,
        transform: showPopup ? 'scale(1)' : 'scale(0)'
    });

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const getShowPopupheight = () => {
        if (showPopup) {
            if (height === 0) setHeight("100vh")
        } else {
            if (height !== 0) {
                setTimeout(() => {
                    setHeight(0)
                }, 200);
            }
        }
    }

    return (
        <div className={`popup-container`} style={{ height: height }} onClick={togglePopup}>
            <animated.div className={`flex flex-col popup ${showPopup ? 'visible' : ''} 
            ${props.type === 'center' || !props.type ? 'center-popup' : 'bottom-popup'}`} style={popupAnimation}
                onClick={(e) => e.stopPropagation()}>
                {props.closeIcon === false ?
                    null : <img src="/images/close.png" alt="" onClick={togglePopup}
                        className='h-[12px] w-[12px] absolute right-[20px] top-[20px]' />}
                <div className={`flex flex-none text-center ${props.type === 'center' || !props.type ? 'text-lg justify-center' : 'text-base'}`}>
                    <strong>{props.title}</strong>
                </div>
                {props.children}
            </animated.div>
        </div >
    );
});

export default Modal;
