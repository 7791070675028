import React, { useEffect, useRef, userRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button"
import { ToastContainer, toast } from 'react-toastify';
import { get } from "../utils/request"

const Invite = () => {
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [userInfo, setUserInfo] = useState({ invite_user: { id: '' } })

    useEffect(() => {
        let preUserInfo = JSON.parse(localStorage.getItem("userInfo"))
        if (preUserInfo) {
            setUserInfo(preUserInfo)
        } else {
            navigate('/', { replace: true })
        }
    }, [])

    const handleClick = async () => {
        if (!code) {
            toast.warn('please input referral code!')
        } else {
            let res = await get('/user/invite/' + code)
            console.log(res)
            if (res.code === 1000) {
                userInfo.invite_user = { id: res.data }
                localStorage.setItem("userInfo", JSON.stringify(userInfo))
                setTimeout(() => {
                    navigate('/start')
                }, 1000);
            } else {
                toast.warn(res.msg)
            }
        }
    }

    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat 
        bg-center items-center px-5 justify-center"
            style={{ backgroundImage: "url(/images/invite.png)" }}>
            <ToastContainer theme="dark" position="top-center" />
            <div className="flex flex-col w-full justify-center items-center">
                <img src="/images/t-logo.png" alt="" className="w-40 rounded-2xl mb-8" />
                <div className="flex bg-black bg-opacity-60 w-full rounded-2xl p-6 justify-center flex-col">
                    <div className="text-base text-center"><strong>Get a Referral Code to Start</strong></div>
                    <div className="flex flex-row justify-around my-2">
                        <div className="flex flex-col items-center bg-black bg-opacity-60 w-32 p-4 rounded-xl">
                            <a href="https://twitter.com/NextUpBSC" target="_blank" rel="noopener noreferrer">
                                <div class="btn">
                                    <img src="/images/twitter-white.png" alt="" />
                                </div>
                            </a>
                            <div>@NextUpBSC</div>
                        </div>
                        <div className="flex flex-col items-center bg-black bg-opacity-60 w-32 p-4 rounded-xl">
                            <a href="https://t.me/NextUpgg" target="_blank" rel="noopener noreferrer">
                                <div class="btn">
                                    <img src="/images/telegram.png" alt="" />
                                </div>
                            </a>
                            <div>NextUp</div>
                        </div>
                    </div>
                    <div className="my-4 flex items-center justify-center">
                        <strong className="w-full">
                            <input type="text" className="bg-black h-10 w-full rounded-full bg-opacity-70
                            border border-[#8a8885] text-black init-input text-center text-lg"
                                placeholder="Enter Referral Code"
                                onChange={(e) =>
                                    setCode(e.currentTarget.value)
                                } />
                        </strong>
                    </div>
                    <Button className='w-full h-10 text-white bg-gradient-to-r from-[#684dfc] to-[#fc33b6]'
                        style={{
                            paddingLeft: 0, paddingRight: 0,
                            cursor: code ? 'auto' : 'not-allowed'
                        }}
                        onClick={handleClick}>
                        <strong>PROCEED</strong>
                    </Button>
                </div>
            </div>

        </div>
    )
}


export default Invite;