const GuideContent = () => {

    return (
        <div className="flex flex-1 flex-col w-full overflow-y-auto">
            {data.map((item, index) => (
                <div className="mb-[14px]" key={index}>
                    <div className="text-sm mb-[5px]">
                        <strong>{item.title}</strong>
                    </div>
                    <div className="text-xs text-[#808080]">
                        {item.content}
                    </div>
                </div>
            ))}
        </div>
    )
}


export default GuideContent;


const data = [
    {
        title: '1. Start Earning by Creating Your Club',
        content: "Purchase keys to create your blockchain-based club, and as the creator, you'll receive 20% of all club rewards."
    },
    {
        title: '2. Join More Clubs to Share Rewards',
        content: "If you believe in a particular club, become a member by purchasing its keys, and share 40% of all club rewards."
    },
    {
        title: '3. Become a Club Manager and Profit',
        content: "The player holding the most keys in the club becomes the manager, enabling the initiation of PvE and PvP activities, and earns 40% of all club rewards."
    },
    {
        title: '4. Hold Keys for Dividends',
        content: "Holding club keys allows you to receive dividends from club activities, distributed according to key holdings."
    },
    {
        title: '5. Trade Keys for Profits',
        content: "Players can buy and sell club keys, with higher trading volumes increasing key value. Trading keys incurs a 10% transaction fee, with 5% going to the club creator and 5% to the platform."
    },
    {
        title: '6. Complete PvE Tasks for Rewards',
        content: "Managers can unlock PvE tasks, completing which earns $NEXT or energy! Each club can only complete one task at a time."
    },
    {
        title: '7. Participate in PvP Battles to Win $NEXT',
        content: "Managers can initiate PvP, representing the club to raid other clubs. Winning grants 20% of the attacked club's $NEXT pool."
    },
    {
        title: '8. Climb the Leaderboards, Accumulate $NEXT Points',
        content: "$NEXT points can be accumulated through daily $NEXT pool rankings. $NEXT points serve as allocation vouchers for receiving platform tokens $NEXT through airdrops."
    },
    {
        title: '9. Invite New Players, Earn $NEXT',
        content: "Use your referral code to invite new players to register, and you'll receive 2% of the invitee's mining income ($NEXT); when the invited member reaches level 8 and accumulates 100 referrals, you can receive 5% of the mining income."
    },
    {
        title: '10. Link Your X, Earn $NEXT Rewards',
        content: "Link your NEXTUP account to your X to receive $NEXT rewards. Once linked, it cannot be unlinked or changed."
    },
    {
        title: '11. Complete Beginner Tasks, Earn $NEXT',
        content: "Follow, retweet, and like NEXTUP official X tweets to earn $NEXT rewards. Each player has only one chance to participate."
    },
    {
        title: '12. Share Achievements, Earn $NEXT',
        content: "When you complete PvE or PvP activities, share your achievements on X platform to earn $NEXT rewards. There is no limit to sharing."
    },
]