import React from "react";

const DataContainer = (props) => {
    return (
        <div
            style={{ lineHeight: "20px", ...props.style }}
            onClick={props.onClick}
            className={`h-[20px] text-sm bg-[#411e60] text-center text-white rounded-full ${props.className ? props.className : ""
                }`}
        //bg-[#a294a3]   bg-opacity-40 
        >
            {props.children}
        </div>
    );
};

export default DataContainer;
