import React, { useState } from "react";
import Button from "./Button";
import DataContainer from "./DataContainer";
import CountTimer from "./CountTimer";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/tools";

const Club = ({ data, index, className }) => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false)

  const showRank = (rank) => {
    switch (rank) {
      case 1:
        return [
          <span key="1">1</span>,
          <span className="text-xs pb-2" key="st">
            st
          </span>,
        ];
      case 2:
        return [
          <span key="2">2</span>,
          <span className="text-xs pb-2" key="nd">
            nd
          </span>,
        ];
      case 3:
        return [
          <span key="3">3</span>,
          <span className="text-xs pb-2" key="rd">
            rd
          </span>,
        ];
      default:
        return rank < 1000 ? ("#" + rank) :
          [
            <span key="3">1000</span>,
            <span className="text-xs pb-2" key="rd">
              +
            </span>,
          ];
    }
  };

  return (
    <div className={`bg-[#270942] flex mb-3 py-3 rounded-2xl ${className ? className : ""}`}>
      <div className="px-4 flex flex-col m-[auto]">
        <div className="w-14 h-14 flex items-center justify-center rounded-full border-white 
        overflow-hidden bg-black bg-opacity-50 border-solid border-2"
          onClick={() => { navigate('/club/' + data.owner.id) }}>
          <img src={data.owner.avatar} alt="" className="w-14" 
          onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }}/>
        </div>
        {/* <div className="relative bg-white top-[-10px] z-1 h-4 font-sm m-auto w-[80%] text-black rounded-full">
          <div className="flex h-4 items-center justify-center">
            {showRank(index + 1)}
          </div>
        </div> */}
      </div>
      <div className="flex-1 flex py-1 flex-col">
        <div className="flex-1 text-sm flex items-center mb-2">
          <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <strong className="text-base">{data.owner.name}</strong>
          </div>
          {data.owner.twitter_name ? (
            <div
              style={{
                background: "url(/images/twitter.png) no-repeat center center",
                backgroundSize: "100%",
              }}
              className="p-1 ml-1 text-xs flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
            ></div>
          ) : null}
          <div
            className="h-[22px] w-[22px] flex items-center justify-center text-xs mx-1"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{data.level}</strong>
          </div>
        </div>
        <div className="flex-1 flex">
          <DataContainer className="flex relative items-center w-28 h-[26px]">
            {/* <img src="/images/ttt.png" alt="" className='h-[22px] w-[22px] relative left-[-2px]' /> */}
            <div className="bg-button-up p-[1px] flex items-center ml-[2px] rounded-full w-[22px] h-[22px]">
              <img src="/images/up.png" alt="" />
            </div>
            <div className="text-left flex-1 flex items-center pl-1 text-[#a08fb0]">Pool</div>
            <div className="pr-1">
              {formatNumber(data.pool)}
            </div>
          </DataContainer>
        </div>

      </div>
      <div className="min-w-28 pr-4 flex items-center justify-center">
        {data.can_looted === "Other" ?
          (new Date(data.loot_date) - new Date() > 0 && !flag ?
            <Button className="h-8 w-24 flex items-center justify-center" disabled >
              <strong><CountTimer lootDate={data.loot_date} notice={setFlag}></CountTimer></strong>
            </Button>
            : <Button className={`h-8 w-24 flex items-center justify-center`}
              disabled={data.pool === 0}
              onClick={() => { navigate('/choose', { state: { data } }) }}  >
              <strong>LOOT</strong>
            </Button>) :
          <Button className="h-8 w-24 flex items-center justify-center text-white" disabled >
            <strong>{data.can_looted}</strong>
          </Button>
        }
      </div>
    </div >
  );
};
export default Club;


// new Date(data.loot_date) - new Date() > 0 ?
//             <Button className="h-8 w-24 flex items-center justify-center" disabled>
//               <strong><CountTimer lootDate={data.loot_date} notice={refresh}></CountTimer></strong>
//             </Button> :
//             <Button className="h-8 w-24 flex items-center justify-center"
//               disabled={data.pool === 0}
//               onClick={() => { navigate('/choose', { state: { data } }) }}  >
//               <strong>LOOT</strong>
//             </Button>