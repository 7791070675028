import React, { useState, useEffect, useRef } from "react";
import WhiteContainer from "../../components/WhiteContainer";
import Button from "../../components/Button";
import Tab from "../../components/Tab";
import GuideContent from "../../components/GuideContent";
import Modal from "../../components/Modal";
import MaskLayer from "../../components/MaskLayer";
import ClubOwnItem from "../../components/ClubOwnItem";
import ClubAllItem from "../../components/ClubAllItem";
import TaskItem from "../../components/TaskItem";
import LoadingContent from "../../components/LoadingContent";
import { useNavigate, useLocation } from "react-router-dom";
import { get, post } from "../../utils/request"
import { taskList } from "../../utils/mockData"
import Activity from "../../components/Activity";
import { useWallets } from "@privy-io/react-auth";
import { ethers } from "ethers";
import contractABI from "../../abi/jumpup";
import { formatNumber, truncateDecimal } from "../../utils/tools"
import { REACT_APP_CONTRACT } from '../../config'
import { ToastContainer, toast } from "react-toastify";
import debounce from 'lodash/debounce';
import Tour from 'reactour';

const tabsList = [
  {
    name: "My Clubs",
  },
  {
    name: "All Clubs",
  },
  {
    name: "Activities",
  }
];
const size = 20;

const Mission = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const otherActive = location.state?.active;
  const locationContent = location.state?.content;
  const scrollRef = useRef(null)
  const clubScrollRef = useRef(null)
  const actScrollRef = useRef(null)
  const contentRef = useRef(null)
  const ref = useRef(null)
  const earnRef = useRef(null)
  const taskRef = useRef(null)
  const containerRef = useRef(null)
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [content, setContent] = useState('');
  const [global, setGlobal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(otherActive ? otherActive : 0);
  const [userInfo, setUserInfo] = useState({
    avatar: "/images/avatar.png",
    pool: 0,
    club: {
      "rank": 0,
      "level": 1,
      "value": 0,
      "up": 0,
      address: ''
    }
  });
  const [clubList, setClubList] = useState([])
  const [ownList, setOwnList] = useState([])
  const [activityList, setActivityList] = useState([])
  const [page, setPage] = useState(1);
  const [clubPage, setClubPage] = useState(0);
  const [actPage, setActPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [clubTotal, setClubTotal] = useState(0);
  const [actTotal, setActTotal] = useState(0);
  const [isScrolledToReFresh, setIsScrolledToReFresh] = useState(false);
  const [keyCount, setKeyCount] = useState(0)
  const [manager, setManager] = useState({
    owner: { avatar: '/images/avatar.png' },
    address: ''
  })
  const [visible, setVisible] = useState(false)
  const [balance, setBalance] = useState(0)
  const [buyEth, setBuyEth] = useState(0)
  const [totalBuyEth, setTotalBuyEth] = useState(0)
  const [isBuying, setIsBuying] = useState(false);
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );

  useEffect(() => {
    if (locationContent) {
      setIsTourOpen(true)
    }
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo !== null) {
      getUserData(userInfo.id)
    }
  }, [])

  useEffect(() => {
    const scrollableDiv = scrollRef.current;
    const handleScroll = debounce(() => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;
      const distanceToBottom = scrollHeight - scrollTop - clientHeight;
      if (distanceToBottom > 0 && distanceToBottom <= 1000) {
        if (!isScrolledToReFresh) {
          setPage(prePage => (prePage + 1))
          setIsScrolledToReFresh(true);
        }
      } else {
        setIsScrolledToReFresh(false);
      }
    }, 20);
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledToReFresh]);

  useEffect(() => {
    const scrollableDiv = clubScrollRef.current;
    const handleScroll = debounce(() => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;
      const distanceToBottom = scrollHeight - scrollTop - clientHeight;
      if (distanceToBottom > 0 && distanceToBottom <= 1000) {
        if (!isScrolledToReFresh) {
          setClubPage(prePage => (prePage + 1))
          setIsScrolledToReFresh(true);
        }
      } else {
        setIsScrolledToReFresh(false);
      }
    }, 20);
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledToReFresh]);

  useEffect(() => {
    const scrollableDiv = actScrollRef.current;
    const handleScroll = debounce(() => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;
      const distanceToBottom = scrollHeight - scrollTop - clientHeight;
      if (distanceToBottom > 0 && distanceToBottom <= 1000) {
        if (!isScrolledToReFresh) {
          setActPage(prePage => (prePage + 1))
          setIsScrolledToReFresh(true);
        }
      } else {
        setIsScrolledToReFresh(false);
      }
    }, 20);
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledToReFresh]);

  useEffect(() => {
    getOwnData()
  }, [page])

  useEffect(() => {
    if (clubPage) {
      getClubData()
    }
  }, [clubPage])

  useEffect(() => {
    if (actPage) {
      getActivities()
    }
  }, [actPage, global])

  useEffect(() => {
    setIsScrolledToReFresh(false);
    if (active === 0) {
      setPage(1)
    } else if (active === 1) {
      setClubPage(1)
    } else if (active === 2) {
      setActPage(1)
    }
  }, [active])

  useEffect(() => {
    if (!visible) {
      setKeyCount(0)
      setBuyEth(0)
      setTotalBuyEth(0)
    } else {
      setBuyInfo()
    }
  }, [visible])

  useEffect(() => {
    getBalance()
  }, [embeddedWallet])

  useEffect(() => {
    if (keyCount > 0 && ref.current) {
      ref.current.togglePopup();
    }
  }, [keyCount])

  const getUserData = async (id) => {
    let result = await get("/user/" + id);
    let { code, data } = result;
    if (code === 1000) {
      setUserInfo(data);
    }
  };

  const getOwnData = async () => {
    if (page === 1 || (page - 1) * size < total) {
      try {
        let result = await get(`/club/relate?page=${page}&size=${size}`);
        let { code, data } = result;
        if (code === 1000) {
          setOwnList(prevList => {
            const newList = page === 1 ? data.list : prevList.concat(data.list ? data.list : []);
            return newList;
          });
          setTotal(data.total);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false)
      }
    }
  };


  const getClubData = async () => {
    if (clubPage === 1 || (clubPage - 1) * size < clubTotal) {
      try {
        let result = await get(`/club/other?page=${clubPage}&size=${size}`);
        let { code, data } = result;
        if (code === 1000) {
          setClubList(prevList => {
            const newList = clubPage === 1 ? data.list : prevList.concat(data.list ? data.list : []);
            return newList;
          });
          setClubTotal(data.total);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const getActivities = async () => {
    if (actPage === 1 || (actPage - 1) * size < actTotal) {
      try {
        let result = await get(`/user/activity?page=${actPage}&size=${size}&filter=${global ? '' : userInfo.club.address}`);
        let { code, data } = result;
        if (code === 1000) {
          setActivityList(prevList => {
            const newList = actPage === 1 ? data.list : prevList.concat(data.list ? data.list : []);
            return newList;
          });
          setActTotal(data.total);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const getBalance = async () => {
    try {
      if (typeof embeddedWallet !== "undefined") {
        const provider = await embeddedWallet?.getEthereumProvider();
        const balance = await provider?.request({
          method: 'eth_getBalance',
          params: [embeddedWallet?.address, 'latest'], // 'latest' 表示最新的区块
        });
        const balanceInEth = ethers.formatEther(balance);
        setBalance(parseFloat(balanceInEth))
      }
    } catch (error) {
      // console.error(error)
    }
  }

  const setBuyInfo = async () => {
    try {
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + manager.address)
      console.log('keyCount:' + keyCount)
      const price = await contract.getBuyPrice(
        // eslint-disable-next-line no-undef
        manager.address, BigInt(keyCount)
      );
      const totalPrice = await contract.getBuyPriceAfterFee(
        // eslint-disable-next-line no-undef
        manager.address, BigInt(keyCount)
      );
      const buyBalanceInEth = ethers.formatEther(price);
      const buyTotalEth = ethers.formatEther(totalPrice);
      setBuyEth(buyBalanceInEth)
      setTotalBuyEth(buyTotalEth)
    } catch (error) {
      console.log(error)
    }
  }

  const buy = async () => {
    try {
      setIsBuying(true);
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      const price = await contract.getBuyPriceAfterFee(
        // eslint-disable-next-line no-undef 
        manager.address, BigInt(keyCount)
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + manager.address)
      console.log('walletAddress:' + embeddedWallet?.address)
      console.log('buyAmount:' + keyCount)
      console.log("price:" + price)
      const txResponse = await contract.buyShares(
        // eslint-disable-next-line no-undef 
        manager.address, BigInt(keyCount), { from: embeddedWallet?.address, value: price }
      );
      console.log("Transaction sent:", txResponse.hash);
      // await txResponse.wait();
      let postData = {
        club_address: manager.address,
        key_num: keyCount,
        key_price: -(totalBuyEth),
        transaction: txResponse.hash,
      };
      let result = await post("/trade/buy", postData);
      let { code, data } = result;
      if (code === 1000) {
        ref.current.togglePopup()
        //访问成功
      }
    } catch (error) {
      console.error(error);
      toast.error('Purchase canceled or failed, please confirm the wallet amount or buy your own key first!')
    } finally {
      getOwnData()
      getClubData()
      setIsBuying(false);
    }
  };

  const buySelf = () => {
    navigate('/start')
    // setKeyCount(1)
    // setManager({ address: userInfo.club.address, owner: { avatar: userInfo.avatar } })
  }

  const openEarn = () => {
    if (earnRef.current) {
      earnRef.current.togglePopup();
    }
  }

  const openTask = () => {
    //设置当前任务的位置
    if (taskRef.current) {
      let height = 0
      for (let i = 0; i < taskList.length; i++) {
        const item = taskList[i];
        if (item.active) {
          break
        } else {
          height += 170
        }
      }
      const container = containerRef.current;
      if (container) {
        container.scrollTop = (height === 0 ? height : height - 100); // 设置滚动的高度，单位为像素
      }
      taskRef.current.togglePopup();
    }
  }

  const getContent = (params) => {
    setContent(params)
    contentRef.current.togglePopup()
  }

  const getCloseFeedBack = () => {
    taskRef.current.togglePopup()
  }


  return (
    <div className="h-full flex flex-col bg-cover bg-no-repeat bg-center"
    // style={{ backgroundImage: "url(/images/mission.png)" }}
    >
      <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
      <div className="py-4 px-1 flex items-center justify-around bg-gradient-to-b from-[#0e051f] to-[#2b0946] rounded-b-2xl"
        onClick={() => { navigate('/main/profile') }}>
        <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
        bg-black bg-opacity-50 border-solid border-2 box-border">
          <img src={userInfo.avatar} alt="" className="w-12"
            onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
        </div>
        <WhiteContainer className="flex w-24 relative items-center rounded-full flex-none ml-2">
          <div
            className="left-[0px] top-[0px] absolute h-[28px] w-[28px] flex items-center justify-center text-lg"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{userInfo.club.level}</strong>
          </div>
          <div className="flex flex-1 pl-[30px] pr-[26px] justify-center">
            {balance > 1 ? formatNumber(balance) : truncateDecimal(balance, 3)}
          </div>
          <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] absolute right-[4px]' />
        </WhiteContainer>
        <WhiteContainer className="w-20 relative rounded-full flex ml-2 flex-none">
          <div className="bg-button-up p-[1px] flex items-center absolute top-[-1px] left-[-1px] rounded-full h-[30px] w-[30px]">
            <img src="/images/up.png" alt="" />
          </div>
          <div className="flex flex-1 pl-[30px] justify-center">{formatNumber(userInfo.club.up)}</div>
        </WhiteContainer>
        <WhiteContainer className="flex-1 flex relative items-center rounded-full ml-2">
          <div className="bg-[#eaa222] p-[5px] flex items-center absolute left-[-1px] top-[-1px] rounded-full h-[30px] w-[30px]">
            <img src="/images/reward.png" alt="" />
          </div>
          <div className="rounded-full bg-[#eaa222] bg-opacity-20 text-[#eaa222] w-16 pl-6 text-center">
            {formatNumber(userInfo.club.rank)}
          </div>
          <div className="flex-1 flex items-center relative pr-[24px]">
            <div className="flex-1">
              {formatNumber(userInfo.pool)}
            </div>
            {/* <img src="/images/up.png" alt="" className="w-[20px] h-[20px] absolute right-[4px]" /> */}
            <div className="bg-button-up p-[1px] flex items-center absolute right-[4px] rounded-full w-[20px] h-[20px]">
              <img src="/images/up.png" alt="" />
            </div>
          </div>
        </WhiteContainer>
      </div>

      <div className="flex px-5 py-1 relative">
        <Tab tabs={tabsList} active={active} onSelect={setActive} className="w-full" />
        {/* {active === 0 ? <WhiteContainer className='px-3 text-sm flex items-center absolute right-5'
          style={{ height: '2rem', backgroundImage: 'linear-gradient(to right, #f48351, #c62655)' }} onClick={openTask}>
          Tasks
        </WhiteContainer> : null} */}

        {active === 2 ? <div className='px-2 pt-2 text-sm flex items-center absolute right-5 h-8'
          onClick={() => { setActivityList([]); setGlobal(!global); setActPage(1) }}>
          {global ? <div className="w-[18px] h-[18px] mr-1 bg-white rounded-full" /> :
            <div className="w-[18px] h-[18px] mr-1 border-[#888] border rounded-full" />} Global
        </div> : null}
      </div>

      <LoadingContent show={loading ? 'true' : undefined} />
      <div className="flex flex-col px-5 overflow-y-auto" ref={scrollRef} >
        {active === 0 && ownList.length ? ownList.map((club, index) => (
          <ClubOwnItem data={club} key={index} backCount={setKeyCount} backData={setManager}
            className={index === 0 && locationContent ? 'guide' : ''} />))
          : null}
      </div>
      <div className="flex flex-col px-5 overflow-y-auto" ref={clubScrollRef} >
        {active === 1 && clubList.length ? clubList.map((club, index) => (
          <ClubAllItem data={club} key={index} backCount={setKeyCount} backData={setManager}
            className={index === 0 && locationContent ? 'guide' : ''} />))
          : null}
      </div>
      <div className="flex flex-col px-5 overflow-y-auto" ref={actScrollRef} >
        {active === 2 && activityList.length ? activityList.map((activity, index) => (
          <Activity data={activity} key={index} />)) : null}
      </div>
      {
        active === 0 && ownList.length ?
          <Button className="mb-2 mx-5 py-2 px-0 text-base text-white flex justify-center 
          bg-gradient-to-r from-[#684dfc] to-[#fc33b6]" style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setActive(1) }}  >
            {/* <strong>LOOKING FOR MORE CLUBS TO JOIN?</strong> */}
            <strong>JOIN MORE CLUBS TO EARN $NEXT</strong>
          </Button> : null
      }

      <Tour
        className="custom-tour"
        showNavigation={false}
        showButtons={false}
        showCloseButton={false}
        isOpen={isTourOpen}
        startAt={0}
        onRequestClose={() => setIsTourOpen(false)}
        steps={[
          {
            selector: '.guide',
            content: locationContent,
          },
        ]}
      />

      {
        active === 0 && ownList.length === 0 ?
          <Button className="mb-2 mx-5 py-2 text-base text-white flex justify-center px-0 bg-gradient-to-r from-[#684dfc] to-[#fc33b6]"
            onClick={buySelf}
          >
            <strong>CREATE YOUR CLUB</strong>
          </Button> : null
      }
      {
        active === 0 ?
          <div className="flex justify-center items-center mb-2">
            <a className="text-[#8f849b] underline" onClick={openEarn}>How to Earn?</a>
          </div> : null
      }

      <MaskLayer ref={earnRef}>
        <div className="h-full w-full flex flex-col px-4">
          <div className="mb-2 text-base"><strong>How To Earn?</strong></div>
          <GuideContent />
        </div>
      </MaskLayer>

      <MaskLayer ref={taskRef}>
        <div className="h-full w-full flex flex-col flex-1 overflow-y-auto items-center mt-8" ref={containerRef}>
          {taskList.map((item, index) => (
            <TaskItem key={index} data={item} getContent={getContent} feedBack={getCloseFeedBack} />
          ))}
        </div>
      </MaskLayer>


      <Modal ref={contentRef} closeIcon={false}>
        {content}
      </Modal>


      <Modal ref={ref} title="BECOME THE MANAGER" backVisible={setVisible}>
        <div className="flex flex-col items-center mt-4 mb-2">
          <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2" >
            <img src={manager.owner.avatar} alt="avatar" className="w-16 h-16"
              onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
          </div>
          <div className="text-sm my-2">Buy {keyCount} key to manage club for</div>
          <div className="flex flex-row items-center mb-4 text-lg">
            <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] mr-2' />
            {buyEth} $BNB
          </div>
          <div className="flex flex-row justify-between items-center w-full px-6 my-2 text-sm">
            <div className="text-[#878788]">Total(inc.fees)</div>
            <div className="flex flex-col">
              <div>{totalBuyEth > 1 ? formatNumber(parseFloat(totalBuyEth)) :
                truncateDecimal(parseFloat(totalBuyEth), 5)} $BNB</div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center w-full px-6 my-2 text-sm">
            <div className="text-[#878788] w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
              {userInfo.club.address}</div>
            <WhiteContainer className="relative ml-2 right-[-5px] text-xs px-2">
              <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] absolute top-[4px] left-[4px]' />
              <div className="min-w-16 ml-[24px] overflow-hidden"
                style={{ color: balance < totalBuyEth ? '#ff6203' : '#3fe51c' }}>
                {balance.toFixed(5)} Available</div>
            </WhiteContainer>
          </div>
          <Button
            className="h-10 text-black flex items-center mt-4 justify-center text-sm"
            onClick={buy} disabled={isBuying || balance < totalBuyEth}
          >
            <strong>BUY NOW</strong>
          </Button>
        </div>
      </Modal>
    </div >
  );
};

export default Mission;



