// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useClient } from "agora-chat-uikit";
import { get } from "../utils/request";

const Main = () => {
  // const [prevPath, setPrevPath] = useState("");
  // const [slideDirection, setSlideDirection] = useState("");
  // const currentOutlet = useOutlet();
  const navigate = useNavigate();
  let location = useLocation();
  const client = useClient();

  const getUserTokenAndInitAgora = async () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let res = await get("/chat/token");
    client
      .open({
        user: userInfo.account,
        agoraToken: res.data,
      })
      .then(() => {
        // console.log({ client });
      });
  };

  useEffect(() => {
    getUserTokenAndInitAgora();
  });

  const [tabs, setTabs] = useState([
    {
      title: "Mission",
      iconOn: "/icon/on-mission.png",
      iconOff: "/icon/mission.png",
      path: "/main",
    },
    {
      title: "Chat",
      iconOn: "/icon/on-chat.png",
      iconOff: "/icon/chat.png",
      path: "/chat",
    },
    {
      title: "Battle",
      iconOn: "/icon/on-battle.png",
      iconOff: "/icon/battle.png",
      path: "/main/battle",
    },
    {
      title: "Explore",
      iconOn: "/icon/on-explore.png",
      iconOff: "/icon/explore.png",
      path: "/main/explore",
    },
    {
      title: "Profile",
      iconOn: "/icon/on-profile.png",
      iconOff: "/icon/profile.png",
      path: "/main/profile",
    },
  ]);

  useEffect(() => {
    localStorage.removeItem('fail')
  }, [])

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      tabs[1].path = "/chat/" + userInfo.id;
      setTabs(tabs);
    }
  }, [location]);

  // const getSlideDirection = (currentPath, previousPath) => {
  //   const currentIndex = tabList.findIndex((tab) => tab.path === currentPath);
  //   const prevIndex = tabList.findIndex((tab) => tab.path === previousPath);
  //   return currentIndex > prevIndex
  //     ? "slide-in-from-right"
  //     : "slide-in-from-left";
  // };

  const handleTabClick = (tab) => {
    // const direction = getSlideDirection(tab.path, prevPath);
    // setSlideDirection(direction); // 设置动画方向
    navigate(tab.path);
  };

  return (
    <div className="w-full  flex flex-col h-full max-w-[768px]">
      <section className={`flex-1 overflow-hidden`}>
        <Outlet />
      </section>
      <section className="bg-[#0e0520] flex">
        {tabs.map((tab) => (
          <div
            key={tab.title}
            className="flex-1 p-2 text-center"
            onClick={() => handleTabClick(tab)}
          >
            <img
              className="h-6 m-auto"
              src={location.pathname === tab.path ? tab.iconOn : tab.iconOff}
              alt={tab.title}
            />
            <div
              className="mt-1 pb-2"
              style={{
                color: location.pathname === tab.path ? "#8f89ff" : "#b0b7d6",
              }}
            >
              {tab.title}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Main;
