import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import Button from "./Button";
import { useSpring, animated, AnimationConfig } from 'react-spring';


const MaskLayer = forwardRef(({ ...props }, ref) => {
    useImperativeHandle(ref, () => ({
        togglePopup
    }))
    const [showPopup, setShowPopup] = useState(false);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        getShowPopupheight()
        if (props.backVisible) {
            props.backVisible(showPopup)
        }
    }, [showPopup, height])

    const popupAnimation = useSpring({
        config: { duration: 0 },
        opacity: showPopup ? 1 : 0,
        transform: showPopup ? 'scale(1)' : 'scale(0)'
    });

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const getShowPopupheight = () => {
        if (showPopup) {
            if (height === 0) setHeight("100vh")
        } else {
            if (height !== 0) {
                setTimeout(() => {
                    setHeight(0)
                }, 200);
            }
        }
    }

    return (
        <div className={`stars`} style={{ height: height }} >
            <animated.div className={`flex flex-col mask`} style={popupAnimation}
                onClick={(e) => e.stopPropagation()}>
                <div className='flex flex-col justify-between w-full h-full'>
                    <div className="w-full flex flex-col pt-4" style={{ height: "calc(100% - 10rem)" }}>
                        {props.children}
                    </div>
                    <div className='flex flex-none pt-6 w-full px-10 h-40'>
                        <Button className="text-white h-8 w-full" style={{ background: "#272727" }}
                            onClick={togglePopup} >
                            CLOSE
                        </Button>
                    </div>
                </div>
            </animated.div>
        </div >
    );
});

export default MaskLayer;
