import React from "react";
import { timeAgo } from ".././utils/tools"
import { useNavigate } from "react-router-dom";


const PersonActivity = ({ data, own }) => {
    const navigate = useNavigate()

    const linkToUser = (id) => {
        if (id !== own) {
            navigate('/club/' + id)
        }
    }

    return (
        <div className="bg-[#a6a1bb] flex bg-opacity-20 mb-3 py-0.75 rounded-lg py-2">
            <div className="ml-4 mr-2 flex items-center justify-center w-20">
                <div className="w-11 h-11 flex flex-none items-center justify-center rounded-full 
                border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2"
                    onClick={() => linkToUser(data.user_club.owner.id)}>
                    <img src={data.user_club.owner.avatar} alt="" className="w-11"
                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                </div>
                <div className="w-11 h-11 flex flex-none items-center justify-center rounded-full border-white 
                overflow-hidden bg-black bg-opacity-50 border-solid border-2 relative right-3"
                    onClick={() => linkToUser(data.club.owner.id)}>
                    <img src={data.club.owner.avatar} alt="" className="w-11"
                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                </div>
            </div>
            <div className="py-1 pr-2">
                <div className="text-sm break-all">
                    <span className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        <strong>{data.user_club.owner.name}</strong> </span>
                    <span className="text-[0.9rem]">
                        {data.transaction_type === 'buy' ? "bought" : "sold"}
                    </span>
                    <span className="text-[0.9rem]">
                        <strong>{" " + data.quantity}</strong>
                    </span>
                    <span className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        <strong>{" " + data.club.owner.name}</strong>
                    </span>
                    <span className="text-[0.9rem]"> key</span>
                </div>
                <div className="text-sm text-[#a8a5a9] flex overflow-hidden">
                    {timeAgo(data.transaction_date)}
                </div>
            </div>
        </div>
    );
};
export default PersonActivity;
