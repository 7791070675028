import React, { useState, useRef, useEffect } from "react";
import WhiteContainer from "../../components/WhiteContainer";
import { useWallets } from "@privy-io/react-auth";
import { ethers } from "ethers";
import Button from "../../components/Button";
import Mark from "../../components/Mark";
import CountTimer from "../../components/CountTimer";
import TickCrossIcon from "../../components/TickCrossIcon";
import { useNavigate } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { post, get, put } from "../../utils/request";
import { formatNumber, truncateDecimal } from "../../utils/tools"
import { ToastContainer, toast } from 'react-toastify';
import { createWalletClient, custom, isAddress, parseEther } from 'viem'
import { goerli, bsc } from 'viem/chains'
import { AnimationConfig } from "react-spring";

const Profile = () => {
    const navigate = useNavigate();
    const ref = useRef(null);
    const withdrawRef = useRef(null);
    const addRef = useRef(null);
    const upRef = useRef(null);
    const lfgRef = useRef(null);
    const liveRef = useRef(null);
    const normalRef = useRef(null);
    const boosterRef = useRef(null);
    const responseRef = useRef(null);
    const twitterRef = useRef(null);
    const telegramRef = useRef(null);
    const levelRef = useRef(null);
    const rewardRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [balance, setBalance] = useState(0);
    const [invite, setInvite] = useState(0);
    const [current, setCurrent] = useState(0);
    const [next, setNext] = useState(0);
    const [boosterRate, setBoosterRate] = useState(0);
    const [boosterResult, setBoosterResult] = useState(0);
    const { wallets } = useWallets();
    const [recipientAddress, setRecipientAddress] = useState("")
    const [transferPrice, setTransferPrice] = useState(0)
    const [txIsLoading, setTxIsLoading] = useState(false)
    const [txHash, setTxHash] = useState("")
    const [stuation, setStuation] = useState({
        "is_follow": false,
        "is_like": false,
        "is_forwad": false
    })
    const [disabled, setDisabled] = useState(false)
    const [receiveState, setReceiveState] = useState(false)
    const [levelData, setLevelData] = useState([{ level: 1, min: 0, max: 1 }])
    const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
    );
    const [user, setUser] = useState({
        avatar: "/images/avatar.png",
        pool: 0,
        name: '',
        twitter_name: '',
        booster: 0,
        withdraw_date: "0001-01-01T08:00:00+08:00",
        use_booster_date: "0001-01-01T08:00:00+08:00",
        club: {
            joined_portifolio: 0,
            member_portifolio: 0,
            key: 0,
            rank: 0,
            level: 1,
            value: 0,
            up: 0,
            power: 0,
            address: "",
            pool: 0,
            free_earn: 0
        }
    });
    const { logout, user: privyUser, linkTwitter, exportWallet } = usePrivy();

    useEffect(() => {
        console.log(privyUser)
    }, [privyUser])

    useEffect(() => {
        getInvite()
        getBooster()
        getUserData()
        getLevel()
    }, []);

    useEffect(() => {
        getBalance()
    }, [embeddedWallet])

    // useEffect(() => {
    //     try {
    //         let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //         if (privyUser !== null && privyUser.twitter.name !== userInfo.name) {
    //             syncTwitterInfo(privyUser);
    //         }
    //     } catch (error) { }
    // }, [privyUser]); 

    const getLevel = async () => {
        let result = await get('/info/level')
        if (result.code === 1000) {
            setLevelData(result.data)
        }
    }

    const getUserData = async () => {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo !== null) {
            let id = userInfo.id;
            let result = await get("/user/" + id);
            let { code, data } = result;
            if (code === 1000) {
                localStorage.setItem("userInfo", JSON.stringify(data));
                setUser(data);
            }
        }

    };

    const syncTwitterInfo = async () => {
        setSyncing(true);
        let res = await post("/user/syncTwitter", privyUser);
        if (res.code === 1000) {
            setSyncing(false);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            setUser(res.data);
        } else {
            toast.warn(res.msg)
        }
    };

    const getInvite = async () => {
        let res = await get("/user/invite/count");
        if (res.code === 1000) {
            setInvite(res.data);
        }
    }

    const getBooster = async () => {
        let res = await get("/user/booster");
        if (res.code === 1000) {
            setCurrent(res.data.current)
            setNext(res.data.next)
        }
    }

    const getBalance = async () => {
        try {
            if (typeof embeddedWallet !== "undefined") {
                const provider = await embeddedWallet?.getEthereumProvider();
                const balance = await provider?.request({
                    method: 'eth_getBalance',
                    params: [embeddedWallet?.address, 'latest'], // 'latest' 表示最新的区块
                });
                const balanceInEth = ethers.formatEther(balance);
                setBalance(parseFloat(balanceInEth))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleMenu = () => {
        if (ref.current) {
            ref.current.togglePopup();
        }
    };

    const addClick = () => {
        if (addRef.current) {
            addRef.current.togglePopup();
        }
    };

    const handleLogout = () => {
        logout();
        localStorage.clear();
        setLoading(true);
        handleMenu()
        setTimeout(() => {
            navigate("/");
        }, 2000);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(user.club.address);
        toast.success('copied!')
    };

    const handleUp = () => {
        // navigate("/main");
        if (levelRef.current) {
            levelRef.current.togglePopup();
        }
    };

    const upClick = () => {
        if (upRef.current) {
            upRef.current.togglePopup();
        }
    };

    const openWithDraw = () => {
        if (withdrawRef.current) {
            withdrawRef.current.togglePopup();
        }
    }

    const lfgClick = () => {
        if (lfgRef.current) {
            lfgRef.current.togglePopup();
        }
    };

    const liveClick = () => {
        if (liveRef.current) {
            liveRef.current.togglePopup();
        }
    };

    const normalReceive = () => {
        if (normalRef.current) {
            normalRef.current.togglePopup()
        }
    }

    const normalClaim = async () => {
        let result = await put('/user/normal/receive', {})
        if (result.code === 1000) {
            await getUserData()
            toast.warning('Successfully get Club pool!')
            normalRef.current.togglePopup()
        } else {
            toast.warning(result.msg)
        }
    }

    const closeNormal = () => {
        normalRef.current.togglePopup()
    }

    const boostReceive = () => {
        if (boosterRef.current) {
            boosterRef.current.togglePopup()
        }
    }

    const boostClaim = async () => {
        let pool = user.club.pool
        let result = await put('/user/booster/receive', {})
        if (result.code === 1000) {
            boosterRef.current.togglePopup()
            responseRef.current.togglePopup()
            setBoosterRate(result.data)
            setBoosterResult(result.data * pool)
            await getUserData()
        } else {
            toast.warning(result.msg)
        }
    }

    const openShareModal = () => {
        if (twitterRef.current) {
            twitterRef.current.togglePopup();
        }
    }
    const openTelegramModal = () => {
        if (telegramRef.current) {
            telegramRef.current.togglePopup();
        }
    }

    const openRewardModal = async () => {
        let result = await get('/quest/novice')
        if (result.code === 20001) {
            toast.warn(result.msg)
        } else if (result.code === 1000) {
            setStuation(result.data)
            let { is_follow, is_like, is_forwad } = result.data
            setReceiveState(is_follow && is_forwad && is_like ? false : true)
            if (rewardRef.current) {
                rewardRef.current.togglePopup();
            }
        }
    }

    const getRewards = async () => {
        setDisabled(true)
        try {
            let result = await get('/quest/novice/collect')
            if (result.code === 20001) {
                toast.warn(result.msg)
            } else if (result.code === 1000) {
                toast.success(result.msg)
                await getUserData()
            }
        } catch (error) {

        } finally {
            rewardRef.current.togglePopup();
            setDisabled(false)
        }
    }

    const recheck = async () => {
        setDisabled(true)
        try {
            let result = await get('/quest/novice/recheck')
            if (result.code === 20001) {
                toast.warn(result.msg)
            } else if (result.code === 1000) {
                setStuation(result.data)
                let { is_follow, is_like, is_forwad } = result.data
                setReceiveState(is_follow && is_forwad && is_like ? false : true)
            }
        } catch (error) {
        } finally {
            setDisabled(false)
        }

    }

    const shareTwitter = () => {

    }

    const shareTelegram = () => {

    }

    const closeBooster = () => {
        boosterRef.current.togglePopup()
    }
    const onTransfer = async () => {
        if (!embeddedWallet) return
        try {
            await embeddedWallet.switchChain(bsc.id)
            const provider = await embeddedWallet.getEthereumProvider()
            const walletClient = createWalletClient({
                account: embeddedWallet.address,
                chain: bsc,
                transport: custom(provider),
            })
            setTxIsLoading(true)
            const _txHash = await walletClient.sendTransaction({
                account: embeddedWallet.address,
                to: recipientAddress,
                value: parseEther(transferPrice.toString()),
            })
            setTxHash(_txHash)
            getBalance()
        } catch (e) {
            console.error('Transfer failed with error ', e)
        }
        setTxIsLoading(false)
    }
    return (
        <div
            className="h-full flex flex-col bg-cover bg-no-repeat bg-center"
        // style={{ backgroundImage: "url(/images/chat.png)" }}
        >
            <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
            <Loading show={loading ? loading : undefined} />
            <div className="h-28 p-2 flex items-center bg-black bg-opacity-50 relative bg-gradient-to-b from-[#0e051f] to-[#2b0946] rounded-b-3xl">
                <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                    <img src={user.avatar} alt="" className="w-16"
                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                </div>
                <div className="flex justify-center overflow-hidden flex-col  w-4/5 ml-3">
                    <div className="flex flex-row">
                        <strong>{user.name}</strong>
                        {user.twitter_name ? (
                            <div
                                style={{
                                    background:
                                        "url(/images/twitter.png) no-repeat center center",
                                    backgroundSize: "100%",
                                }}
                                className="p-1 mx-1 text-xs flex items-center rounded-full m-[1px] h-[18px] w-[18px] justify-center"
                            ></div>
                        ) : null}
                    </div>
                    <div className="text-xs mt-1 mb-1 text-[#8c8397]">Wallet Balance($BNB)</div>
                    <div className="flex flex-row">
                        <WhiteContainer className="w-32 relative flex">
                            <img
                                src="/images/eth.png"
                                alt=""
                                className="h-[24px] w-[24px] absolute top-[2px] left-[2px]"
                            />
                            <div className="w-full">
                                {balance > 1 ? formatNumber(balance) : truncateDecimal(balance, 3)}
                            </div>
                            <div
                                className="bg-button-up right-[-1px] top-[0px] flex items-center absolute rounded-full h-[28px] w-[28px] p-1.5"
                                onClick={addClick}
                            >
                                <img src="/images/plus.png" alt="" />
                            </div>
                        </WhiteContainer>
                        {privyUser && typeof privyUser.twitter === "undefined" ? (
                            <WhiteContainer
                                className="w-[140px] ml-2 border-none flex justify-center items-center"
                                style={{
                                    backgroundImage:
                                        "linear-gradient(to right, #6e1a3b, #700da6)", height: 30
                                }}
                            >
                                <WhiteContainer
                                    className="w-[138px] h-[26px] text-sm flex justify-center items-center"
                                    style={{ background: "#000", opacity: 0.9 }}
                                    onClick={linkTwitter}
                                >
                                    +Connect Socials
                                </WhiteContainer>
                            </WhiteContainer>
                        ) : (
                            <WhiteContainer
                                className="px-2 ml-2 text-sm flex justify-center align-middle items-center bg-button-up"
                                // style={{
                                //     backgroundImage:
                                //         "linear-gradient(to right, #6e1a3b, #700da6)",
                                // }}
                                onClick={() => syncTwitterInfo()}
                            >
                                {!syncing ? (
                                    "Refresh"
                                ) : (
                                    <img
                                        src="/icon/refresh.png"
                                        alt="refresh"
                                        style={{
                                            animation: "rotate 2s linear infinite",
                                        }}
                                        className="h-5 w-5"
                                    />
                                )}
                            </WhiteContainer>
                        )}
                    </div>
                </div>
                <div
                    className="w-[25px] top-[1.2rem] right-3 absolute bg-opacity-50"
                    onClick={handleMenu}
                >
                    <img src="/images/menu.png" alt="bnb" />
                </div>
            </div>

            <div className="flex-1 overflow-scroll">
                <div className="flex flex-col p-3 pb-1">
                    <div className="flex flex-row mb-2 items-center">
                        <div className="w-28 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-right pr-1">
                            Portfolio Value
                        </div>
                        <WhiteContainer className="flex relative flex-1 ml-1">
                            <img
                                src="/images/eth.png"
                                alt=""
                                className="h-[24px] w-[24px] absolute left-[6px] top-[2px]"
                            />
                            <div className="max-w-32 absolute right-[10px] text-right">
                                <strong>
                                    {user.club.joined_portifolio > 1 ? formatNumber(user.club.joined_portifolio) :
                                        truncateDecimal(user.club.joined_portifolio, 4)}
                                </strong>
                            </div>
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-row mb-2 items-center">
                        <div className="w-28 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-right pr-1">
                            Members Value
                        </div>
                        <WhiteContainer className="flex relative flex-1 ml-1">
                            <img
                                src="/images/eth.png"
                                alt=""
                                className="h-[24px] w-[24px] absolute left-[6px] top-[2px]"
                            />
                            <div className="max-w-32 absolute right-[10px] text-right">
                                <strong>
                                    {user.club.member_portifolio > 1 ? formatNumber(user.club.member_portifolio) :
                                        truncateDecimal(user.club.member_portifolio, 4)}
                                </strong>
                            </div>
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-row mb-2 items-center">
                        <div className="w-28 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-right pr-1">
                            Sold Keys
                        </div>
                        <WhiteContainer className="flex relative flex-1 ml-1">
                            <img
                                src="/images/eth.png"
                                alt=""
                                className="h-[24px] w-[24px] absolute left-[6px] top-[2px]"
                            />
                            <div className="max-w-32 absolute right-[10px] text-right">
                                <strong>
                                    {formatNumber(user.club.key)}
                                </strong>
                            </div>
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-row mb-2 items-center">
                        <div className="w-28 text-sm text-right pr-1">Power</div>
                        <div className="flex flex-1 ml-1 flex-row">
                            <WhiteContainer className="flex flex-1 relative items-center">
                                <div
                                    className="flex items-center justify-center text-sm h-[24px] w-[24px] ml-[3px]"
                                    style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
                                >
                                    <strong>{user.club.level}</strong>
                                </div>
                                <div className="w-8 text-left ml-[1px]">
                                    <strong>{Math.floor(formatNumber(user.club.power))}</strong>
                                </div>
                                <div className="absolute right-[27px] flex items-center">
                                    <strong className="text-[10px] mr-[1px] text-[#ab97b8]">Club Value:</strong>
                                    <strong>
                                        {user.club.value > 1 ? formatNumber(user.club.value) : truncateDecimal(user.club.value, 3)}
                                    </strong>
                                </div>
                                <img
                                    src="/images/eth.png"
                                    alt=""
                                    className="absolute right-[2px] h-[24px] w-[24px] top-[2px]"
                                />
                            </WhiteContainer>
                            <button
                                className="h-[26px] w-18 flex items-center justify-center ml-[2px] text-xs px-1 py-2 bg-[#6450fd] 
                            text-center text-white rounded-full mt-[1px]"
                                onClick={handleUp}
                            >
                                <strong>LEVEL UP</strong>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row mb-2 items-center ">
                        <div className="w-28 text-sm text-right pr-1">Frees Earned</div>
                        <WhiteContainer className="flex relative flex-1 ml-1">
                            <img
                                src="/images/eth.png"
                                alt=""
                                className="h-[24px] w-[24px] absolute left-[6px] top-[2px]"
                            />
                            <div className="max-w-32 absolute right-[10px] text-right">
                                <strong>
                                    {user.club.free_earn < 0 ? 0 :
                                        user.club.free_earn < 1 ? truncateDecimal(user.club.free_earn, 4) :
                                            formatNumber(user.club.free_earn)}
                                </strong>
                            </div>
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-row mb-2 items-center ">
                        <div className="w-28 text-sm text-right pr-1">$NEXT Points</div>
                        <WhiteContainer className="flex relative flex-1 ml-1">
                            <div className="bg-button-up flex items-center justify-center rounded-full h-[24px] w-[24px] absolute left-[6px] top-[2px]">
                                <img src="/images/up.png" alt="" />
                            </div>
                            <div className="max-w-32 absolute right-[30px] text-right">
                                <strong>{user.club.up}</strong>
                            </div>
                            <Mark className="absolute top-[4px] right-[5px]" onClick={upClick} />
                        </WhiteContainer>
                    </div>
                    <div className="flex flex-row mb-1 items-center">
                        <div className="w-28 text-sm text-right pr-1">$NEXT</div>
                        <WhiteContainer className="flex relative flex-1 ml-1">
                            <div className=" flex items-center justify-center rounded-full h-[24px] w-[24px] absolute left-[6px] top-[2px]">
                                <img src="/images/ttt.png" alt="" />
                            </div>
                            <div className="max-w-32 absolute right-[30px] text-right">
                                <strong>{
                                    Math.abs(user.pool) < 1 ? truncateDecimal(user.pool, 4) :
                                        formatNumber(user.pool)
                                }</strong></div>
                            <Mark className="absolute top-[4px] right-[5px]" onClick={lfgClick} />
                        </WhiteContainer>
                    </div>
                </div>

                <div
                    className="flex flex-col px-5 pb-4 my-2 mx-4 h-[10.5rem] bg-black justify-around bg-cover bg-no-repeat bg-center rounded-2xl"
                    style={{ backgroundImage: "url(/images/pool.png)" }}
                >
                    <div><strong>$NEXT Pool</strong></div>
                    <div className="py-0 flex px-2">
                        <WhiteContainer className="flex relative items-center mr-2"
                            style={{ background: '#00000099' }}>
                            <div className="bg-[#eaa222] p-[5px] flex items-center absolute left-[2px] top-[2px] rounded-full h-[24px] w-[24px]">
                                <img src="/images/reward.png" alt="" />
                            </div>
                            <div className="rounded-xl bg-[#eaa222] bg-opacity-40 text-[#fde8a7] w-24 pl-6 text-center">
                                <strong>{user.club.rank}</strong>
                            </div>
                        </WhiteContainer>
                        <WhiteContainer className="flex flex-1 relative items-center mr-2"
                            style={{ background: '#00000099' }}>
                            <div className="flex-1 flex items-center relative ml-1">
                                <div className="flex flex-1 justify-center ">
                                    {truncateDecimal(user.club.pool, 4)}</div>
                                <img src="/images/ttt.png" alt="" className="w-[24px] h-[24px] absolute left-0" />
                            </div>
                        </WhiteContainer>
                        <div
                            className="bg-button-up flex flex-none items-center justify-center rounded-full p-1.5 h-[28px] w-[28px]"
                            onClick={normalReceive}
                        >
                            <img src="/images/upload.png" alt="" />
                        </div>
                    </div>

                    {new Date(user.use_booster_date) - new Date() > 0 ?
                        <Button className="h-10 text-lg" disabled>
                            <strong><CountTimer lootDate={user.use_booster_date} notice={getUserData} ></CountTimer></strong>
                        </Button>
                        : (user.club.pool >= 1000 ?
                            <Button className='text-white flex flex-row justify-center h-10 items-center bg-white'
                                onClick={boostReceive}>
                                <div className="flex text-xl items-center">
                                    <strong className="text-black">BOOST</strong>
                                    <WhiteContainer style={{ background: "#6450fd", height: 26 }}
                                        className='text-black px-3 flex justify-center ml-2 items-center text-xl'>
                                        <img src="/images/rocket.png" alt="" className="h-[16px] w-[16px] mr-1" />
                                        <strong>-1</strong>
                                    </WhiteContainer>
                                </div>
                            </Button> :
                            <Button className="h-10 text-black text-lg" disabled>
                                <strong>Minimum amount: 1000</strong>
                            </Button>)}

                </div>

                <div className="flex flex-col mx-5 mt-4 mb-2 rounded-lg p-3 justify-between bg-[#2b282c] bg-opacity-50">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center">
                            <div className="text-[0.825rem]">Trivia Extra Lives</div>
                            <WhiteContainer className="w-14 relative ml-2">
                                <div className="bg-button-up pt-2 left-[-1px] flex items-center absolute top-[-1px] rounded-full h-[30px] w-[30px] overflow-hidden border">
                                    <img src="/images/avatar.png" alt="" className="relative top-[-3px] h-[30px] w-[30px]" />
                                </div>
                                <div className="ml-3 bg-black rounded-tr-full rounded-br-full pl-3">
                                    {formatNumber(invite)}
                                </div>
                            </WhiteContainer>
                            <Mark className="ml-2" onClick={liveClick} />
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="text-[0.825rem]">Booster</div>
                            <WhiteContainer className="w-14 relative ml-2">
                                <div className="bg-button-up p-2 left-[-1px] flex items-center absolute top-[-1px] rounded-full h-[30px] w-[30px]">
                                    <img src="/images/rocket.png" alt="" />
                                </div>
                                <div className="ml-3 bg-black rounded-tr-full rounded-br-full pl-3">
                                    {formatNumber(user.booster)}
                                </div>
                            </WhiteContainer>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-lg px-3 py-1 justify-between bg-[#151416] my-3">
                        <div className="flex flex-row justify-between">
                            <div className="text-[#8a8a8b] text-sm mt-1 mb-1">
                                Next Booster
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="text-[#8a8a8b] text-sm mt-1 mb-1">
                                    Group Portfolio
                                </div>
                                {/* <div className="bg-button-primary p-1 flex items-center justify-center rounded-full h-[20px] w-[20px] mx-1">
                                    <img src="/svg/eth.svg" alt="" />
                                </div> */}
                                <img
                                    src="/images/eth.png"
                                    alt=""
                                    className="h-[20px] w-[20px] mx-1"
                                />

                                <div className="text-xs">
                                    <strong>{truncateDecimal(current, 5)}/{next}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="flex mb-3 items-center justify-between">
                                <div className="text-right">
                                    <span className="text-xs font-semibold inline-block">{formatNumber((current / next) * 100)}%</span>
                                </div>
                            </div>
                            <div className="flex h-2 mb-2 overflow-hidden text-xs bg-[#281d2e] rounded relative top-[-5px]">
                                <div
                                    style={{ width: `${formatNumber((current / next) * 100)}%`, background: "#6450fd" }}
                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                                ></div>
                            </div>
                            <img
                                src="/images/rocket.png"
                                alt=""
                                style={{ height: 16, width: 16, zIndex: 9, left: `${(current / next) * 100 - 1}%` }}
                                className={`absolute top-[50%]`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <Button
                            className={`flex flex-1 text-xs py-2 items-center justify-center bg-white`}
                            style={{ padding: "0.5rem 1rem" }}
                            onClick={openShareModal} disabled={user.twitter_name == ""}
                        >
                            <img
                                src="/images/twitter-black.png"
                                alt=""
                                style={{ height: 14, width: 14, marginRight: 10 }}
                            />
                            <strong className="text-black">SHARE ON X</strong>
                        </Button>
                        <Button
                            className={`flex flex-1 ml-2 text-xs py-2 bg-transparent items-center justify-center border-2`}
                            style={{ padding: "0.5rem 1rem" }}
                            onClick={() => navigate("/group")}
                        >
                            <strong>VIEW MY GROUP</strong>
                        </Button>
                    </div>
                    <Button className="mt-3 py-2 text-black text-xs w-full flex justify-center items-center border-2 border-[#6450fd]"
                        onClick={openRewardModal} >
                        {/* <strong>NEWBIE REWARDS</strong> */}
                        <strong>X TASK</strong>
                    </Button>
                </div>
                {/* <div className="flex mx-3 mt-4 mb-2 rounded-lg p-3 justify-between">
                    <div className="flex-1 mr-2">
                        <Button className="h-10 text-black text-sm px-2 w-full flex justify-center items-center" onClick={openShareModal} disabled={user.twitter_name == ""}>
                            <img className="w-4 h-4" src="/icon/x.png" alt="x" /><strong>SHARE ON X</strong>
                        </Button>
                    </div>
                    <div className="flex-1 ml-2">
                        <Button className="h-10 text-black text-sm w-full  flex justify-center items-center" onClick={openTelegramModal} disabled={user.twitter_name == ""}>
                            <img className="w-6 h-6" src="/icon/telegram.png" alt="telegram" /><strong>TELEGRAM</strong>
                        </Button>
                    </div>
                </div> */}


            </div>

            <Modal ref={ref} title="" type="center" closeIcon={false}>
                <div className="flex flex-col items-center mt-4 justify-center flex-1">
                    <div
                        className="w-full h-8 cursor-pointer bg-button-up rounded-full text-white flex items-center justify-center mb-4"
                        onClick={addClick}
                    >
                        <strong>DEPOSIT $BNB</strong>
                    </div>
                    <div
                        className="w-full h-8 cursor-pointer bg-button-up rounded-full text-white flex items-center justify-center mb-4"
                        onClick={openWithDraw}
                    >
                        <strong>WITHDRAW $BNB</strong>
                    </div>
                    <div
                        className="w-full cursor-pointer h-8 bg-button-up rounded-full text-white flex items-center justify-center mb-6"
                        onClick={exportWallet}
                    >
                        <strong>EXPORT WALLET</strong>
                    </div>
                    <div className="w-full flex relative pt-6 border-gray-500 border-2 h-32 mb-4 pb-3 rounded-3xl">
                        <div className="absolute w-[150px] h-8 top-[-10px] text-center bg-[#271336] text-sm"
                            style={{ left: "calc(50% - 75px)" }}>OFFICAIL LINK</div>
                        <a href="https://twitter.com/nextupbsc" target="_blank"
                            className="flex-1 flex flex-col justify-center items-center text-sm">
                            <div className="bg-white  rounded-full p-2 mb-2">
                                <img src="/icon/x.png" className="bg-white h-8 w-8 rounded-full" alt="x" />
                            </div>
                            <span>X(Twitter)</span>
                        </a>
                        <a href="https://t.me/NextUpgg" target="_blank"
                            className="flex-1 flex flex-col justify-center items-center text-sm">
                            <div className="bg-white  rounded-full p-2 mb-2">
                                <img src="/icon/telegram.png" className="h-8 w-8" alt="telegram" />
                            </div>
                            <span>Telegram</span>
                        </a>
                        <a href="https://docs.nextup.gg/" target="_blank"
                            className="flex-1 flex flex-col justify-center items-center text-sm">
                            <div className="bg-white  rounded-full p-2 mb-2">
                                <img src="/icon/whitepaper.png" className="h-8 w-8" alt="whitepaper" />
                            </div>
                            <span>Whitepaper</span>
                        </a>
                    </div>
                    <div
                        className="w-full h-10 bg-gray-700 cursor-pointer  rounded-full text-white flex items-center justify-center"
                        onClick={handleLogout}
                    >
                        <strong>LOG OUT</strong>
                    </div>
                </div>
            </Modal>
            <Modal ref={withdrawRef} title="" type="center">
                <input
                    type='text'
                    id='recipient-address'
                    placeholder='0x123...'
                    autoComplete='off'
                    onChange={(e) =>
                        setRecipientAddress(e.currentTarget.value)
                    }
                    className='form-input w-full rounded-full border-2 border-gray-200 px-4 py-3 bg-black mt-8'
                />
                <input type="number"
                    id='recipient-address'
                    placeholder='balance'
                    autoComplete='off'
                    onChange={e => setTransferPrice(e.target.value)}
                    step="any"
                    min="0"
                    className='form-input mt-2 w-full rounded-full border-2 border-gray-200 px-4 py-3 bg-black'
                />
                <div className="py-4 text-center">
                    Your balance:{balance.toFixed(4)} BNB
                </div>
                <button
                    type='button'
                    className='mt-2 w-full py-4 mb-10 rounded-md bg-indigo-700 text-sm font-semibold text-white shadow-sm disabled:bg-indigo-300'
                    disabled={
                        !recipientAddress || !isAddress(recipientAddress) || txIsLoading
                    }
                    onClick={onTransfer}
                >
                    WITHDRAW {transferPrice} BNB
                </button>


                {txHash && (
                    <div className="py-4 text-center">
                        See your transaction on{' '}
                        <a
                            className='underline'
                            href={`https://bscscan.com/tx/${txHash}`}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            bscscan
                        </a>
                        .
                    </div>
                )}
            </Modal>
            <Modal
                ref={addRef}
                title="Deposit $BNB to your NEXTUP Wallet Address"
                closeIcon={false}
            >
                <div className="w-full flex justify-around flex-col flex-1 items-center">
                    <div className="flex flex-row items-center mt-2 break-all">
                        {user.club.address}
                        <img
                            src="/images/copy.png"
                            alt=""
                            style={{
                                height: 14,
                                width: 14,
                                marginLeft: 10,
                                cursor: "pointer",
                            }}
                            onClick={handleCopy}
                        />
                    </div>
                    <Button className="h-8 w-40 mt-2" onClick={addClick}>
                        <strong>GOT IT</strong></Button>
                </div>
            </Modal>

            <Modal ref={upRef} title="LEADERBOARD & $NEXT POINTS" closeIcon={false}>
                <div className="w-full flex my-2 flex-col flex-1 items-start text-sm overflow-y-auto">
                    <div>
                        Complete missions & engage in PVP battles to accumulate $NEXT to
                        climb up the leaderboard.
                    </div>
                    <div className="mt-1"> Your unclaimed $NEXT determines your
                        Leaderboard rank, which impacts the daily $NEXT points earnings. More
                        $NEXT = higher leaderboard rank.
                    </div>
                    <div className="mt-1">
                        Extracting $NEXT from the pool resets your rank and start in the bottom of the leaderboard.
                    </div>
                    <div className="my-2 board-table w-full">
                        <table className="w-full">
                            <thead>
                                <tr><th colSpan="2">Ranking Rewards</th></tr>
                            </thead>
                            <tbody>
                                {tableData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.key}</td>
                                        <td>{item.value}<span>PTS</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

            <Modal ref={lfgRef} title="WAYS TO EARN" closeIcon={false}>
                <div className="w-full break-all flex justify-around my-4 flex-col flex-1">
                    <div className="mb-1">Manage Clubs</div>
                    <div className="text-gray-400 mb-2 text-sm">
                        Become a manager by being the top key holder of the club. Complete
                        missions and engage in PVP battles. Raid other clubs to loot the
                        $NEXT. Managers earn 40% of their club $NEXT yield.
                    </div>
                    <div className="mb-1">Rewards From Club Loot:</div>
                    <div className="text-gray-400 mb-2 text-sm">
                        Join your favorite clubs by buying their keys. 40% of all yield from
                        club activities is distributed to club members. The more club keys
                        you hold, the higher your club yield share
                    </div>
                    <div className="mb-1">Leaderboard And $NEXT Points</div>
                    <div className="text-gray-400 mb-2 text-sm">
                        Complete missions, engage in PVP battles, and loot $NEXT to climb up
                        the leaderboard position which determines your daily $NEXT points
                        earnings.
                    </div>
                </div>
            </Modal>

            <Modal ref={liveRef} title="" closeIcon={false}>
                <div className="w-full break-all flex justify-around my-4 flex-col flex-1">
                    For every invitee that joins using your referral code, you get an
                    extra life for the Daily Trivia game. This could potentially increase
                    you chances of winning daily rewards.
                </div>
            </Modal>

            <Modal ref={normalRef} title="NOTICE" closeIcon={false}  >
                <div className="w-full break-all flex justify-around my-4 flex-col flex-1">
                    <div className="text-sm">Extracting from the $NEXT pool will reset your leaderboard ranking and may affect your daily $NEXT point earnings.
                        Cool-down periods are triggered for the following:</div>
                    <div className="text-sm">·Extracting $NEXT: + 16 hours</div>
                    <div className="text-sm">·Applying Booster: + 2 hours</div>
                    <div className="text-sm">·PvE Mission Claims: + 5 minutes per claim</div>
                    <div className="text-sm mt-2">Would you like to continue?</div>

                    {/* {user.club.key > 0 ? (user.club.pool >= 1000 ?
                        (new Date(user.withdraw_date) - new Date() > 0 ?
                            <Button className="h-8 mt-4" disabled>
                                <strong><CountTimer lootDate={user.withdraw_date} notice={getUserData}></CountTimer></strong>
                            </Button> :
                            <Button className="h-8 mt-4 text-white text-sm" onClick={normalClaim}>
                                <strong>CLAIM</strong>
                            </Button>
                        ) :
                        <Button className="h-8 mt-4 text-white text-sm" disabled>
                            <strong>Minimum amount: 1000</strong>
                        </Button>) :
                        <Button className="h-8 mt-4 text-white text-sm" onClick={() => { navigate('/start') }}>
                            <strong>CREATE YOUR CLUB TO CONTINUE</strong>
                        </Button>} */}

                    {
                        user.club.key > 0 ?
                            (new Date(user.withdraw_date) - new Date() > 0 ?
                                <Button className="py-2 mt-4" disabled>
                                    <strong><CountTimer lootDate={user.withdraw_date} notice={getUserData}></CountTimer></strong>
                                </Button> : (user.club.pool >= 1000 ?
                                    <Button className="py-2 mt-4 text-white text-sm" onClick={normalClaim}>
                                        <strong>CLAIM</strong>
                                    </Button> : <Button className="py-2 mt-4 text-white text-sm" disabled>
                                        <strong>Minimum amount: 1000</strong>
                                    </Button>))
                            :
                            <Button className="mt-4 text-sm py-2" onClick={() => { navigate('/start') }}>
                                <strong>CREATE YOUR CLUB TO CONTINUE</strong>
                            </Button>
                    }


                    <Button className="mt-4 text-sm py-2" onClick={closeNormal}><strong>CLOSE</strong></Button>

                </div>
            </Modal>

            <Modal ref={boosterRef} title="BOOST YOUR $NEXT" closeIcon={false} >
                <div className="w-full break-all flex justify-around my-4 flex-col flex-1 items-center">
                    <div className="text-sm">Current $NEXT Pool</div>
                    <WhiteContainer className="flex flex-row h-8 ml-1 mt-2 items-center pr-2">
                        <img src="/images/ttt.png" alt=""
                            className="h-[20px] w-[20px] flex flex-none"
                        />
                        <div className="flex flex-1 ml-2">
                            {user.club.pool < 10000 ? truncateDecimal(user.club.pool, 4) : formatNumber(user.club.pool)}
                        </div>
                    </WhiteContainer>
                    <div className="text-sm w-full text-center mt-4">
                        Apply a multiplier to increase your NEXT pool! Keep in mind,
                        the multiplier factor can fluctuate randomly.
                    </div>
                    <div className="text-sm mt-4">After Applying Multiplier:</div>
                    <WhiteContainer className="flex flex-row h-8 ml-1 mt-2 items-center pr-2">
                        <img src="/images/ttt.png" alt=""
                            className="h-[20px] w-[20px] flex flex-none"
                        />
                        <div className="flex flex-1 ml-2">
                            {user.club.pool < 10000 ? truncateDecimal(user.club.pool, 4) : formatNumber(user.club.pool)} -
                            {user.club.pool * 2 < 10000 ? truncateDecimal(user.club.pool * 2, 4) : formatNumber(user.club.pool * 2)}
                        </div>
                    </WhiteContainer>
                    <div className="text-sm mt-4">Available Multipliers: {user.booster}</div>
                    {user.club.key > 0 ? <Button className="h-8 mt-4 text-white text-sm w-full" onClick={boostClaim}
                        disabled={user.booster === 0}>
                        <strong>BOOST</strong>
                    </Button>
                        : <Button className="h-8 mt-4 text-white text-sm" onClick={() => { navigate('/start') }}>
                            <strong>CREATE YOUR CLUB TO BOOST</strong>
                        </Button>}
                    <Button className="h-8 mt-4 bg-white text-sm w-full" onClick={closeBooster}>
                        <strong>CLOSE</strong>
                    </Button>
                    <div className="text-sm mt-2 text-center text-[#f7ba3d]">
                        Boosters can be used once every 8 hours.When applying a booster,
                        it triggers a 2 hours cooldown for extractions.</div>
                </div>
            </Modal>

            <Modal ref={responseRef} title="BOOST RESULT">
                <div className="w-full break-all flex justify-start my-4 flex-col flex-1 items-center">
                    <div className="flex w-full mt-2">
                        You have successfully received $NEXT with a multiplier of {boosterRate}：</div>
                    <div className="flex w-full mt-4 text-2xl justify-center text-[#f7ba3d]">
                        {truncateDecimal(boosterResult, 4)}</div>
                </div>
            </Modal>

            <Modal ref={twitterRef} title="Share Your Referral Code Through X(Twitter)">
                <div className="w-full break-all flex justify-start my-4 flex-col flex-1 items-center bg-gray-800 p-2 rounded-md">
                    Playing missions and battles, looting #CryptoTrillionaires on @NextUpBSC!<br />
                    Use my code to start:<br />
                    http://www.nextup.gg/share/@{user.twitter_name}<br />
                    Ready to #NEXTUP? Let's play!  #SocialGaming
                </div>
                <div className="flex justify-center">
                    <a
                        className="flex-1 h-12 max-w-[200px] flex justify-center items-center px-8 bg-[#6450fd] text-center text-white rounded-full"
                        href={`https://twitter.com/intent/tweet?text=Playing%20missions%20and%20battles%2C%20looting%20%23CryptoTrillionaires%20on%20%40NextUpBSC!%20Use%20my%20code%20to%20start%3A%20http%3A%2F%2Fwww.nextup.gg%2Fshare%2F%40${user.twitter_name}%20Ready%20to%20%23NEXTUP%3F%20Let%27s%20play!%20%23SocialGaming`} target="_blank">
                        <img className="w-4 h-4 mr-2" src="/images/twitter-white.png" alt="x" /><strong>POST</strong>
                    </a>
                </div>
            </Modal>

            <Modal ref={telegramRef} title="Share Your Referral Code Through Telegram">
                <div className="w-full break-all flex justify-start my-4 flex-col flex-1 items-center bg-gray-800 p-2 rounded-md">
                    Playing missions and battles, looting #CryptoTrillionaires on @NextUpBSC!<br />
                    Use my code to start:<br />
                    http://www.nextup.gg/share/@{user.twitter_name}<br />
                    Ready to #NEXTUP? Let's play!  #SocialGaming
                </div>
                <div className="flex justify-center">
                    <a className="flex-1 h-12 max-w-[200px] flex justify-center items-center px-8 bg-[#fcb833] text-center text-black rounded-full"
                        href={`https://t.me/share/url?url=http://www.nextup.gg%2Fshare%2F%40${user.twitter_name}&text=Playing%20missions%20and%20battles%2C%20looting%20%23CryptoTrillionaires%20on%20%40NextUpBSC!%20Use%20my%20code%20to%20start%3A%20http%3A%2F%2Fwww.nextup.gg%20Ready%20to%20%23NEXTUP%3F%20Let%27s%20play!%20%23SocialGaming`} target="_self">
                        <img className="w-6 h-6" src="/icon/telegram.png" alt="telegram" /><strong>POST</strong>
                    </a>
                </div>
            </Modal>

            <Modal ref={levelRef} closeIcon={false} >
                <div className="w-full break-all flex my-4 flex-col items-start text-sm overflow-y-auto">
                    <div className="mb-[3px]"> The Club power is determined by 3 factors, X, Y and N</div>
                    <div className="mb-[3px]"> X = Total value of all Members</div>
                    <div className="mb-[3px]"> Y = Total value of all Joined</div>
                    <div className="mb-[3px]"> (For Wish accounts, Y=current Key Price)</div>
                    <div className="mb-[3px]">N = Number of Club Keys are generated and bought</div>
                    <div className="mb-[3px]">1.To unlock X’s full impact, you must ensure at least 50 of your own Club Keys are generated and bought. Failure to meet this requirement will result in a reduced effect.</div>
                    <div className="mb-[3px]">2.The smaller value between X and 10Y determines the power calculation.</div>
                    <div className="mb-[3px]">Club levels range between 1 (lowest) and 15 (highest)</div>
                    <div className="mt-2 board-table w-full">
                        <table className="w-full min-w-[250px]">
                            <thead>
                                <tr><th>Level</th>
                                    <th>Min Power</th>
                                    <th>Max Power</th></tr>
                            </thead>
                            <tbody>
                                {levelData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.level}</td>
                                        <td>{item.min}</td>
                                        <td>{item.max > 9999 ? '10k+' : item.max}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

            <Modal ref={rewardRef} closeIcon={false} >
                <div className="flex flex-col pt-1 pb-3 justify-between flex-1">
                    <div className="w-full flex justify-start my-4 flex-col flex-1 items-center bg-gray-800 p-2 rounded-md">
                        <div>Earn rewards by visiting the
                            <a href={`https://twitter.com/NextUpBSC/status/1763469324444921879`}
                                target="_blank" style={{ textDecoration: 'underline' }}>
                                <strong> twitter link </strong>
                            </a>and completing the actions of following, retweeting, and liking.
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-base">Follow</div>
                            <div className="text-lg my-2">
                                <TickCrossIcon value={stuation.is_follow} />
                            </div>
                        </div>
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-base">Retweet</div>
                            <div className="text-lg my-2">
                                <TickCrossIcon value={stuation.is_forwad} />
                            </div>
                        </div>
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-base">Like</div>
                            <div className="text-lg my-2">
                                <TickCrossIcon value={stuation.is_like} />
                            </div>
                        </div>
                    </div>
                    {!receiveState ?
                        <Button className='h-10 mt-4 flex flex-row items-center justify-center'
                            onClick={getRewards} disabled={disabled}>
                            {disabled ? <img
                                src="/icon/refresh-black.png"
                                alt="refresh"
                                style={{
                                    animation: "rotate 2s linear infinite",
                                }}
                                className="h-6 w-6 mr-2"
                            /> : null}<strong>Get Rewards</strong>
                        </Button> :
                        <Button className='h-10 mt-4 flex flex-row items-center justify-center'
                            onClick={recheck} disabled={disabled}>
                            {disabled ? <img
                                src="/icon/refresh-black.png"
                                alt="refresh"
                                style={{
                                    animation: "rotate 2s linear infinite",
                                }}
                                className="h-6 w-6 mr-2"
                            /> : null} <strong>Recheck</strong>
                        </Button>
                    }
                </div>
            </Modal >

        </div >
    );
};

export default Profile;


const tableData = [
    { key: "1st", value: "1000" },
    { key: "2nd/3rd", value: "500" },
    { key: "#4~#10", value: "250" },
    { key: "#11~#20", value: "150" },
    { key: "#21~#50", value: "100" },
    { key: "#51~#100", value: "75" },
    { key: "#101~#200", value: "50" },
    { key: "#201~#500", value: "40" },
    { key: "#501~#1000", value: "20" },
    { key: "#1001~#2000", value: "10" },
    { key: "#2001~#5000", value: "5" },
    { key: "#5001~#100k", value: "1" },
]