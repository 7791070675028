import React, { useState } from "react";
import DataContainer from "./DataContainer";
import Button from "./Button";
import CountTimer from "./CountTimer";
import { useNavigate } from "react-router-dom";
import { formatNumber, truncateDecimal } from "../utils/tools";

const ClubOwnItem = ({ data, backCount, backData, className }) => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false)

  const detail = (data) => {
    navigate('/mission', { state: { data } })
  }

  const claim = (data) => {
    navigate('/claim', { state: { data } })
  }

  const buyKey = (data, count) => {
    backCount(count)
    backData(data)
  }

  return (
    <div className={`bg-[#270942] flex mb-3 px-4 py-3 rounded-2xl ${className ? className : ''}`}>
      <div className="flex items-center">
        <div className="pr-2 flex flex-col">
          {data.need_buy === 0 ? <div className="w-14 h-5 text-center">
            <img className="h-5 m-auto relative top-[4px]" src="/images/crown.png" alt="" />
          </div> : null}
          {data.need_buy > 0 ? <div className="w-14 h-2.5 text-center"></div> : null}
          <div className="w-14 h-14 flex items-center justify-center rounded-full 
                    border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2"
            onClick={() => { navigate('/chat/' + data.owner.id) }}>
            <img src={data.owner.avatar} alt="avatar" className="w-14" />
          </div>
          <div className="relative bg-white mt-[-10px] z-1 h-4 font-sm m-auto min-w-[100%] text-black rounded-full">
            <div className="flex flex-row h-4 items-center justify-center overflow-hidden">
              <img className="h-3 mr-1 flex flex-none" src="/images/key.png" alt="key" />
              <div className="ml-[1px] text-sm"><strong> x {data.you_own}</strong></div>
            </div>
          </div>
          {data.need_buy > 0 ? <div className="w-14 h-2.5 text-center"></div> : null}
        </div>
      </div>
      <div className="flex-1 py-1 flex flex-col justify-between">
        <div className="flex-1 text-sm flex flex-none items-center">
          <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <strong className="text-base">{data.owner.name}</strong>
          </div>
          <div
            className="h-[22px] w-[22px] flex items-center justify-center text-xs mx-1"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{data.level}</strong>
          </div>
          {data.owner.twitter_name ? (
            <div
              style={{
                background: "url(/images/twitter.png) no-repeat center center",
                backgroundSize: "100%",
              }}
              className="p-1 text-xs flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
            ></div>
          ) : null}
        </div>
        <div className="flex w-full">
          <div className="flex flex-col">
            <div className="text-[#8f849b] text-sm mb-[2px]">Price</div>
            <DataContainer className="flex relative items-center justify-center pl-[20px] text-sm max-w-16 w-16">
              <img src="/images/eth.png" alt="" className='h-[22px] w-[22px] absolute left-[-2px]' />
              <div className="mx-1">
                {data.price > 1 ? formatNumber(data.price) : truncateDecimal(data.price, 3)}
              </div>
            </DataContainer>
          </div>
          <div className="flex flex-none w-[10px]"></div>
          <div className="flex flex-col">
            <div className="text-[#8f849b] text-sm mb-[2px]">Energy</div>
            <DataContainer className="flex relative items-center justify-center pl-[20px] text-sm max-w-16 w-16">
              <img src="/images/energy-orange.png" alt="" className='h-[22px] w-[22px] absolute left-[-2px]' />
              <div className="mx-1">
                {formatNumber(data.energy)}
              </div>
            </DataContainer>
          </div>
        </div>
      </div>
      <div className="min-w-28 flex items-center justify-end">
        {
          data.need_buy > 0 ?
            <Button className="h-8 min-w-24 flex items-center justify-center flex-row relative" style={{ padding: '0 2px' }}
              onClick={() => buyKey(data, data.need_buy)}>
              <div><strong>BUY</strong></div>
              <img className="h-3 mr-1" src="/images/key-white.png" alt="key" />
              <div><strong>x {formatNumber(data.need_buy)}</strong></div>
              <div className='absolute top-8 text-xs text-[#8f849b] break-all w-24'>
                To be Manager
              </div>
            </Button>
            :
            (
              data.task === null ? <Button
                className="h-8 w-24 flex items-center justify-center"
                onClick={() => {
                  navigate("/quest", {
                    state: {
                      id: data.owner.id, address: data.address,
                      avatar: data.owner.avatar, name: data.owner.name,
                      level: data.level, energy: data.energy
                    }
                  })
                }}>
                <strong>EARN</strong>
              </Button> :
                (new Date(data.task.end_date) - new Date() > 0 && !flag ?
                  <Button className="h-8 w-24 flex items-center justify-center relative"
                    onClick={() => detail(data)}>
                    <strong>DETAILS</strong>
                    <CountTimer lootDate={data.task.end_date} notice={setFlag}
                      className='absolute top-8 text-xs break-all text-[#8f849b] w-24'>
                      Ends in:
                    </CountTimer>
                  </Button>
                  :
                  <Button className="h-8 w-24 flex items-center justify-center text-white"
                    style={{ backgroundImage: 'linear-gradient(to right, #684dfc, #fc33b6)' }}
                    onClick={() => claim(data)}>
                    <strong>CLAIM</strong></Button>
                )
            )
        }
      </div>

    </div >
  );
};
export default ClubOwnItem;
