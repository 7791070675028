import { useNavigate } from "react-router-dom";


const TaskItem = ({ data, getContent, feedBack }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        return
        if (data.active) {
            feedBack()
            if (data.path === '/main') {
                window.location.reload()
                navigate(data.path, { state: { content: data.title, ...data.state } })
            } else {
                navigate(data.path, { state: { content: data.title, ...data.state } })
            }
        }
    }

    return (
        <div className={`${data.lock ? 'my-4 h-[135px] border-[#383838]' :
            (data.active ? 'h-[145px] border-[#d9831d] my-8' : 'h-[135px] border-[#3eb544] my-4')} 
            w-[310px] rounded-[12px] bg-cover flex flex-none items-end border transform cursor-pointer`}
            style={{
                backgroundImage: "url(/images/c-mission.png)", transform: `${data.active ? 'scale(1.15)' : 'scale(1)'}`,
                boxShadow: `${!data.active ? 'none' : "0 0 15px #d9831d"}`
            }} onClick={handleClick}>
            {data.lock ?
                <div className="absolute top-0 left-0 w-full h-full bg-[#262222] bg-opacity-90 
                flex items-center justify-center pb-[40px] rounded-[12px]">
                    <div className="w-[50px] p-1 rounded-full bg-black bg-opacity-60" style={{ aspectRatio: "1/1" }}>
                        <img src="/images/lock-black.png" alt="lock" className="relative"
                            onClick={(e) => { e.stopPropagation(); getContent(data.content) }} />
                    </div>
                </div> :
                null}
            <div className="flex items-center justify-between h-[40px] px-[16px] w-full relative rounded-br-[12px]"
                style={{
                    background: `${data.lock ? 'linear-gradient(90deg,transparent,#878787)' :
                        (data.active ? "linear-gradient(90deg,transparent,#d9831d)" :
                            "linear-gradient(90deg,transparent,#3eb544)")}`,
                }}
                onClick={(e) => { e.stopPropagation(); getContent(data.content) }}>
                {data.active || data.lock ?
                    <div className="w-[20px] h-[20px] ">
                    </div> :
                    <div className="w-[20px] h-[20px] bg-[#3eb544] rounded-full flex items-center justify-center">
                        <img src="/icon/right.png" alt="" style={{ height: 10 }} />
                    </div>
                }
                <div className="flex items-center text-sm break-all">
                    <div className="mr-2 text-[#a8a7a7]">{data.title}</div>
                    <div>{data.reward}</div>
                    <img src="/images/ttt.png" alt="" className="w-[14px] ml-2 mt-[1px]" />
                </div>
            </div>

        </div >
    )
}


export default TaskItem; 
