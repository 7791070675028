import React, { useState, useEffect } from 'react';

function CountdownTimer() {
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        // 获取当前时间的小时数
        const current = new Date();
        const now = new Date(current.toLocaleString('en-US', { timeZone: 'UTC' }));
        const currentHour = now.getHours();

        // 计算目标时间的小时数
        let targetHour = 8;
        if (currentHour >= 8 && currentHour < 20) {
            targetHour = 20;
            // 计算当前时间到目标时间的毫秒数
            const targetTime = new Date(now);
            targetTime.setHours(targetHour, 0, 0, 0);
            setCountdown(targetTime.getTime() - now.getTime());
        } else if (currentHour >= 20) {
            // 晚于晚上8点，设置到第二天的早上8点
            const tomorrowMorning = new Date(now);
            tomorrowMorning.setDate(tomorrowMorning.getDate() + 1);
            tomorrowMorning.setHours(8, 0, 0, 0);
            const targetTime = tomorrowMorning.getTime();
            setCountdown(targetTime - now.getTime());
        } else {
            const targetTime = new Date(now);
            targetTime.setHours(targetHour, 0, 0, 0);
            setCountdown(targetTime.getTime() - now.getTime());
        }


        // 更新倒计时每秒钟
        const intervalId = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1000);
        }, 1000);

        // 清除定时器
        return () => clearInterval(intervalId);
    }, []);

    // 将毫秒数转换为时分秒格式
    const formatTime = (milliseconds) => {
        const seconds = Math.floor(milliseconds / 1000) % 60;
        const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
        const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className='ml-1'>
            {formatTime(Math.abs(countdown))}
        </div>
    );
}

export default CountdownTimer;
