import React, { useState, useEffect } from "react";
import { get, post } from "../utils/request"
import WhiteContainer from "../components/WhiteContainer";
import { useNavigate } from "react-router-dom";

const ShareDetail = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        avatar: "/images/avatar.png",
        pool: 0,
        club: {
            "rank": 0,
            "level": 1,
            "value": 0,
            "up": 0,
            address: ''
        }
    });

    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userInfo !== null) {
            getUserData(userInfo.id)
        }
    }, [])

    const getUserData = async (id) => {
        let result = await get("/user/" + id);
        let { code, data } = result;
        if (code === 1000) {
            setUserInfo(data);
        }
    };

    return (
        <div className="w-full h-full flex flex-col max-w-[768px] overflow-hidden">
            <div className="h-32 flex flex-col items-center py-6 bg-cover bg-no-repeat bg-center relative w-full"
                style={{ backgroundImage: "url(/images/group.png)", zIndex: 1 }}>
                <div className="text-lg"><strong>Share on X</strong></div>
                <div className="text-lg"><strong>Earn 50,000 $NEXT Points every day!</strong></div>
                <div className="text-sm mt-4">Will end after 09:46:59</div>
            </div>
            <div className="p-4 flex flex-col flex-1 overflow-hidden">
                <div className="flex-1 overflow-scroll">
                    <div className="flex flex-row">
                        <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
                        bg-black bg-opacity-50 border-solid border-2 box-border">
                            <img src={userInfo.avatar} alt="avatar" className="w-12" />
                        </div>
                        <div className="flex flex-col ml-2 justify-between">
                            <div className="text-xs flex flex-row">social score
                                <div className="ml-1 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808080]"
                                    onClick={() => { }}>
                                    <strong className="text-[#808080]">?</strong></div>
                            </div>
                            <WhiteContainer className="flex relative">
                                <div className="max-w-32 text-right px-2">
                                    <strong>
                                        0
                                    </strong>
                                </div>
                            </WhiteContainer>
                        </div>
                        <div className="flex flex-col ml-2 justify-between">
                            <div className="text-xs flex flex-row items-center">$NEXT points
                                {/* Estimated  */}
                            </div>
                            <div>
                                <WhiteContainer className="flex relative">
                                    <div className="max-w-32 text-right px-2">
                                        <strong>
                                            0
                                        </strong>
                                    </div>
                                </WhiteContainer>
                            </div>
                        </div>
                        <div className="h-full ml-2 bg-button-up flex flex-none items-center justify-center rounded-full
                     p-2 h-[32px] w-[32px] relative" style={{ top: 'calc(3rem - 32px)' }}
                            onClick={() => { }}
                        >
                            <img src="/images/share.png" alt="" />
                        </div>
                    </div>
                    <div className="text-sm flex flex-row items-center justify-center h-8 border-[#70707090] border my-4 rounded-lg">
                        <strong>your social score</strong>
                        <div className="ml-1 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808080]"
                            onClick={() => { }}>
                            <strong className="text-[#808080]">?</strong></div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-xs">Tweets</div>
                            <div className="text-lg my-2"><strong>0/3</strong></div>
                            <div className="text-xs mb-1">detail</div>
                            <WhiteContainer className='w-[90%]'>123</WhiteContainer>
                        </div>
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-xs">Answer</div>
                            <div className="text-lg my-2"><strong>0/3</strong></div>
                            <div className="text-xs mb-1">detail</div>
                            <WhiteContainer className='w-[90%]'>123</WhiteContainer>
                        </div>
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-xs">Quote</div>
                            <div className="text-lg my-2"><strong>0/3</strong></div>
                            <div className="text-xs mb-1">detail</div>
                            <WhiteContainer className='w-[90%]'>123</WhiteContainer>
                        </div>
                        <div className="flex flex-col items-center flex-1">
                            <div className="text-xs">Repost</div>
                            <div className="text-lg my-2"><strong>0/3</strong></div>
                            <div className="text-xs mb-1">detail</div>
                            <WhiteContainer className='w-[90%]'>123</WhiteContainer>
                        </div>
                    </div>
                    <div className="mt-4 h-24 flex flex-row justify-between items-end" style={{ borderTop: ' 2px solid #7070704d' }}>
                        <div className="flex flex-1 pr-2 relative">
                            <div className="absolute bottom-[0px] flex flex-col items-center left-[calc(50%-1.5rem)]">
                                <div className="mb-1"><strong>Top 2</strong></div>
                                <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
                                        bg-black bg-opacity-50 border-solid border-2 box-border">
                                    <img src={userInfo.avatar} alt="avatar" className="w-12" />
                                </div>
                            </div>
                            <div className="h-6 w-full rounded-tl-lg rounded-tr-lg"
                                style={{ background: 'linear-gradient(to bottom, #c62655, #f4835120)' }}></div>
                        </div>
                        <div className="flex flex-1 px-1 relative">
                            <div className="absolute bottom-[10px] flex flex-col items-center left-[calc(50%-1.5rem)]">
                                <div className="mb-1"><strong>Top 1</strong></div>
                                <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
                                        bg-black bg-opacity-50 border-solid border-2 box-border">
                                    <img src={userInfo.avatar} alt="avatar" className="w-12" />
                                </div>
                            </div>
                            <div className="h-8 w-full rounded-tl-lg rounded-tr-lg"
                                style={{ background: 'linear-gradient(to bottom, #c62655, #f4835120)' }}></div>
                        </div>
                        <div className="flex flex-1 pl-2 relative">
                            <div className="absolute bottom-[0px] flex flex-col items-center left-[calc(50%-1.5rem)]">
                                <div className="mb-1"><strong>Top 3</strong></div>
                                <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
                                        bg-black bg-opacity-50 border-solid border-2 box-border">
                                    <img src={userInfo.avatar} alt="avatar" className="w-12" />
                                </div>
                            </div>
                            <div className="h-6 w-full  rounded-tl-lg rounded-tr-lg"
                                style={{ background: 'linear-gradient(to bottom, #c62655, #f4835120)' }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-3">
                        <div>Global Social Score: <strong>59.4k</strong></div>
                        <div className="text-xs mt-0">Reward Countdown: <strong>0d 17h 00mm 56s</strong></div>
                    </div>

                    <div className="flex flex-row py-2 rounded-lg text-xs"
                        style={{ background: 'linear-gradient(to right, #fe9550, #ae204c)' }}>
                        <div className="flex flex-[1.5] justify-center items-center">User</div>
                        <div className="flex flex-1 justify-start items-center">Social Score</div>
                        <div className="flex flex-1 justify-start items-center">$NEXT points</div>
                    </div>

                    <div className="h-32 overflow-y-auto">
                        {data.map((item, index) => (
                            <div className="flex flex-row py-3 rounded-lg text-xs" key={index} style={{ borderTop: ' 1px solid #7070704d' }}>
                                <div className="flex flex-[1.5] justify-start items-center">
                                    <div className="w-8 h-8 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
                                        bg-black bg-opacity-50 border-solid border-2 box-border mx-2">
                                        <img src={userInfo.avatar} alt="avatar" className="w-8" />
                                    </div>
                                    {item.user}</div>
                                <div className="flex flex-1 justify-start items-center pl-4">{item.score}</div>
                                <div className="flex flex-1 justify-start items-center">
                                    <div className="bg-button-up  flex items-center justify-center rounded-full h-[20px] w-[20px] mx-2">
                                        <img src="/images/up.png" alt="" />
                                    </div>
                                    {item.point}</div>
                            </div>
                        ))}
                    </div>

                    <div className="h-10 w-full my-2">
                        <button className={`w-full h-full flex text-sm bg-[#2c222c] rounded-full items-center justify-center`}
                            onClick={() => navigate(-1)}>
                            <strong>CLOSE</strong>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ShareDetail;


const data = [
    { user: '小池', avatar: "/images/avatar.png", score: 10000, point: 8424 },
    { user: 'bdsfdhgdsfg', avatar: "/images/avatar.png", score: 4700, point: 73848 },
    { user: 'Dgrgf', avatar: "/images/avatar.png", score: 3600, point: 8424 },
    { user: 'sd', avatar: "/images/avatar.png", score: 2423, point: 8424 },
    { user: '公输', avatar: "/images/avatar.png", score: 315, point: 8424 },
    { user: '小a', avatar: "/images/avatar.png", score: 900, point: 8424 },
]
