import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../components/Modal";
import Mark from "../components/Mark";
import Button from "../components/Button";
import { post, get } from "../utils/request";
import { formatNumber, truncateDecimal } from "../utils/tools";
import { ToastContainer, toast } from "react-toastify";

const ClaimMission = () => {
    const earnRef = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data;
    const [earnings, setEarnings] = useState({
        "club": 0,
        "manager": 0,
        "owner": 0,
        "you": 0,
        total_keys: 0,
        you_keys: 0
    })
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rate, setRate] = useState(1);
    const [totalRate, setTotalRate] = useState(1);

    useEffect(() => {
        getReward()
        getRate()
    }, [])

    useEffect(() => {
        setTotalRate(rate * data.task.quest.rate)
    }, [rate])

    const getRate = async () => {
        let result = await get('/trade/rate')
        let { code, data } = result
        if (code === 1000) {
            setRate(data)
        }
    }

    //完成任务
    const claim = async () => {
        setLoading(true)
        try {
            let result = await post('/quest/finish/' + data.task.id, {})
            let { code, msg } = result
            if (code === 1000) {
                toast.success("Successfully complete the mission!");
            } else {
                toast.error(msg)
            }
        } catch (error) {

        } finally {
            setFlag(true)
            setLoading(false)
        }
    }

    const getReward = async () => {
        let result = await post('/quest/reward', { club_address: data.address, quest_id: data.task.quest.id })
        if (result.code === 1000) {
            setEarnings(result.data)
        }
    }

    const openModal = () => {
        if (earnRef.current) {
            earnRef.current.togglePopup()
        }
    }

    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat bg-top"
            style={{ backgroundImage: `url(${data.task.quest.image})` }} >
            <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
            <div className="h-14 relative top-4 z-10 w-full flex items-center justify-center">
                <div className="w-[70%] rounded-xl  bg-[#120f0f] border-2 border-[#555] px-2 h-12 text-center
                flex items-center"  style={{ whiteSpace: 'nowrap', overflow: 'auto' }}>
                    <strong>{data.task.quest.title}</strong>
                </div>
            </div>


            <div className="fixed bottom-20 w-full max-w-[768px] px-4 flex flex-col items-center py-10 text-sm">
                <div className="flex flex-col py-3 bg-black w-full bg-opacity-60 rounded-xl">
                    <div className="flex w-full flex-row justify-between my-3">
                        <div className="flex justify-end flex-[4] ">
                            Total Club Earnings:
                        </div>
                        <div className="flex justify-start flex-[3] pl-4">
                            <img src="/images/ttt.png" alt="" className="w-[20px] mr-[2px]" />
                            {earnings.manager / 0.4 > 1 ? formatNumber(earnings.manager / 0.4 * totalRate) :
                                truncateDecimal(earnings.manager / 0.4 * totalRate, 5)}
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between mb-4">
                        <div className="flex justify-end flex-[4] ">
                            My Earnings:
                        </div>
                        <div className="flex justify-start flex-[3] pl-4">
                            <img src="/images/ttt.png" alt="" className="w-[20px] mr-[2px]" />
                            {earnings.you > 1 ? formatNumber(earnings.you * totalRate) : truncateDecimal(earnings.you * totalRate, 5)}
                            <Mark className="ml-[4px]" onClick={openModal} />
                        </div>
                    </div>
                    {data.task.quest.type === 0 ? <div className="flex w-full flex-row justify-between mb-4">
                        <div className="flex justify-end flex-[4] ">
                            Special Earnings:
                        </div>
                        <div className="flex justify-start flex-[3] pl-4">
                            <img src="/images/energy-yellow.png" alt="" className="w-[14px] mr-[5px]" />
                            50
                            <div className="ml-[4px] flex items-center justify-center rounded-full h-[20px] w-[20px]">
                            </div>
                        </div>
                    </div> : null}
                </div>

                {!flag ? <Button className="my-4 w-full py-3 text-base text-white flex justify-center"
                    onClick={claim} disabled={loading}
                    style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <strong>CLAIM</strong>
                </Button> :
                    <div className="h-12 w-full my-4">
                        <button className={`w-full h-full flex text-sm bg-[#6450fd] rounded-full items-center justify-center`}
                            onClick={() => navigate(-1, { state: null, replace: true })}>
                            <strong>CLOSE</strong>
                        </button>
                    </div>
                }
            </div>


            <Modal ref={earnRef} closeIcon={false} title="EARNINGS DETAIL">
                <div className="flex flex-col items-center mt-4 w-full text-sm">
                    <div className="w-full text-left text-base">The total club earnings will split 40% to the manager,
                        40% among all members and 20% to the club owner.</div>
                    <div className="flex flex-col w-full my-3">
                        <div className="flex justify-between w-full my-1">
                            <div>Total Club Earnings</div>
                            <div className="flex items-center">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.manager / 0.4 > 1 ? formatNumber(earnings.manager / 0.4 * totalRate) :
                                    truncateDecimal(earnings.manager / 0.4 * totalRate, 5)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1">
                            <div className="flex flex-[2]">Manager's Share</div>
                            <div className="flex flex-1 justify-center items-center">40%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.manager > 1 ? formatNumber(earnings.manager * totalRate) : truncateDecimal(earnings.manager * totalRate, 5)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1">
                            <div className="flex flex-[2]">Club Owner's Shares</div>
                            <div className="flex flex-1 justify-center items-center">20%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.owner > 1 ? formatNumber(earnings.owner * totalRate) : truncateDecimal(earnings.owner * totalRate, 5)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1 items-center">
                            <div className="flex flex-col flex-[2]">
                                <div>Key Holders Share</div>
                                <div>You Hold {earnings.you_keys}/{earnings.total_keys} Keys</div>
                            </div>
                            <div className="flex flex-1 justify-center items-center">
                                {earnings.total_keys === 0 ? 0 : formatNumber(40 * (earnings.you_keys / earnings.total_keys))}%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.total_keys === 0 ? 0 : (earnings.you_keys / earnings.total_keys) * earnings.manager > 1 ?
                                    formatNumber(((earnings.you_keys / earnings.total_keys) * earnings.manager * totalRate)) :
                                    truncateDecimal(
                                        parseFloat((earnings.you_keys / earnings.total_keys) * earnings.manager * totalRate), 5
                                    )}
                            </div>
                        </div>
                        <div className="border-[#ccc] border-t mt-2"></div>

                        <div className="flex justify-between w-full my-2">
                            <div className="flex flex-[2]">My Earnings</div>
                            <div className="flex flex-1 justify-center">
                                {earnings.you === 0 ? "-" : formatNumber(earnings.you / (earnings.manager / 0.4) * 100) + '%'}</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.you > 1 ? formatNumber(earnings.you * totalRate) : truncateDecimal(earnings.you * totalRate, 5)}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >


        </div >
    );
};

export default ClaimMission;
