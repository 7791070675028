import React from "react";
import DataContainer from "./DataContainer";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { formatNumber, truncateDecimal } from "../utils/tools";

const ClubAllItem = ({ data, backCount, backData, className }) => {
    const navigate = useNavigate();

    const buyKey = (data, count) => {
        backCount(count)
        backData(data)
    }

    return (
        <div className={`bg-[#270942] flex mb-3 px-4 py-2 rounded-2xl ${className ? className : ""}`}>
            <div className="flex items-center py-3">
                <div className="pr-2 flex flex-col">
                    <div className="w-14 h-14 flex items-center justify-center rounded-full 
                    border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2"
                        onClick={() => { navigate('/club/' + data.owner.id) }}>
                        <img src={data.owner.avatar} alt="" className="w-14"
                            onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
                    </div>
                    <div className="relative bg-white mt-[-10px] z-1 h-4 font-sm m-auto min-w-[100%] text-black rounded-full">
                        <div className="flex flex-row h-4 items-center justify-center overflow-hidden">
                            <img className="h-3 mr-1 flex flex-none" src="/images/key.png" alt="key" />
                            {/* <div className="ml-[1px] w-1"></div> */}
                            <div className="ml-[1px] text-sm"><strong> x {data.key}</strong></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 py-2 flex flex-col justify-between">
                <div className="flex-1 text-sm flex flex-none items-center">
                    <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
                        <strong className="text-sm">{data.owner.name}</strong>
                    </div>
                    {data.owner.twitter_name ? (
                        <div
                            style={{
                                background: "url(/images/twitter.png) no-repeat center center",
                                backgroundSize: "100%",
                            }}
                            className="p-1 ml-1 text-xs flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
                        ></div>
                    ) : null}
                    <div
                        className="h-[20px] w-[20px] flex items-center justify-center text-xs mx-1"
                        style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
                    >
                        <strong>{data.level}</strong>
                    </div>
                </div>
                <div className="flex-1 flex-none">
                    <DataContainer className="flex relative items-center w-32">
                        <img src="/images/eth.png" alt="" className='h-[22px] w-[22px] relative left-[-2px]' />
                        <div className="text-left flex-1 flex items-center">Price</div>
                        <div className="pr-1">
                            {data.price > 1 ? formatNumber(data.price) : truncateDecimal(data.price, 5)}
                        </div>
                    </DataContainer>
                </div>
                <div className="flex-1 text-sm flex flex-none">
                    <div className="text-[#8f849b] mr-1 flex">Manager:</div>
                    <div className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {data.manager.name}
                    </div>
                </div>
            </div>
            <div className="flex-1 flex items-center justify-end">
                <Button
                    className="h-8 min-w-24 flex items-center justify-center flex-row"
                    style={{ padding: '0 2px' }}
                    disabled={data.key === 0}
                    onClick={() => { buyKey(data, data.need_buy) }}>
                    <div><strong>BUY</strong></div>
                    <img className="h-3 mr-[3px]" src="/images/key-white.png" alt="key" />
                    <div><strong>x {formatNumber(data.need_buy)}</strong></div>
                </Button>
            </div>

        </div >
    );
};
export default ClubAllItem;
