import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "../components/Tab";
import ClubItem from "../components/ClubItem";
import Modal from "../components/Modal";
import EditableDiv from "../components/EditableDiv";
import { get, post } from "../utils/request";
import contractABI from "../abi/jumpup";
import { useWallets } from "@privy-io/react-auth";
import { ethers } from "ethers";
import PersonActivity from "../components/PersonActivity";
import Button from "../components/Button";
import { REACT_APP_CONTRACT } from "../config"
import { ToastContainer, toast } from 'react-toastify';
import { formatNumber, truncateDecimal } from "../utils/tools";
import LoadingContent from "../components/LoadingContent";

const Club = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { wallets } = useWallets();

  const sellRef = useRef(null);
  const buyRef = useRef(null);
  const scrollRef = useRef(null)
  const addRef = useRef(null);

  const [active, setActive] = useState(0);
  const [type, setType] = useState(0);
  const [isBuying, setIsBuying] = useState(false);
  const [activities, setActivities] = useState([]);
  const [joined, setJoined] = useState([]);
  const [members, setMembers] = useState([]);
  const [own, setOwn] = useState(0);
  const [user, setUser] = useState({
    avatar: "",
    name: "",
    twitter_name: "",
    club: {
      value: 0,
      price: 0,
      rank: 0,
    },
  });
  const [selfData, setSelfData] = useState({
    id: '',
    club: {
      key: 0,
      address: ''
    },
  })
  const [tabs, setTabs] = useState([
    { name: "Members(0)" },
    { name: "Joined(0)" },
    { name: "Activities" },
    { name: "Trades", },
  ]);
  const [isOpen, setIsOpen] = useState(false)
  const [address, setAddress] = useState('');
  const [buyVisible, setBuyVisible] = useState(false)
  const [sellVisible, setSellVisible] = useState(false)
  const [buyAmount, setBuyAmount] = useState(1)
  const [buyEth, setBuyEth] = useState(0)
  const [totalBuyEth, setTotalBuyEth] = useState(0)
  const [sellAmount, setSellAmount] = useState(1)
  const [sellEth, setSellEth] = useState(0)
  const [ownEth, setOwnEth] = useState(0)
  const [totalSellEth, setTotalSellEth] = useState(0)
  const [balance, setBalance] = useState(0)
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isScrolledToNewBottom, setIsScrolledToNewBottom] = useState(false);
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );

  useEffect(() => {
    getUserData()
    getJoined()
    getMembers()
  }, [id])

  useEffect(() => {
    getSelfData()
  }, [])

  useEffect(() => {
    const scrollableDiv = scrollRef.current;
    const handleScroll = () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (!isScrolledToNewBottom) {
          setPage(prePage => (prePage + 1))
          console.log('滚动到了底部');
          setIsScrolledToNewBottom(true);
        }
      } else {
        setIsScrolledToNewBottom(false);
      }
    };
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (active === 0) {
      getMembers()
    } else if (active === 1) {
      getJoined()
    } else {
      setType(active === 2 ? 0 : 1)
    }
  }, [page, active])

  useEffect(() => {
    getActivities()
  }, [type])

  useEffect(() => {
    setPage(1)
  }, [active, id])

  useEffect(() => {
    if (address) getOwnData(address)
  }, [address])

  useEffect(() => {
    if (!buyVisible) {
      setBuyAmount(1)
      setBuyEth(0)
      setTotalBuyEth(0)
      setIsOpen(false)
    } else {
      setBuyInfo()
      getBalance()
    }
  }, [buyVisible])

  useEffect(() => {
    setBuyInfo()
  }, [buyAmount])

  useEffect(() => {
    if (!sellVisible) {
      setSellAmount(1)
      setSellEth(0)
      setTotalSellEth(0)
      setIsOpen(false)
    } else {
      setSellInfo()
      getBalance()
    }
  }, [sellVisible])

  useEffect(() => {
    setSellInfo()
  }, [sellAmount])

  const addClick = () => {
    if (addRef.current) {
      addRef.current.togglePopup();
    }
  };

  const getBalance = async () => {
    try {
      if (typeof embeddedWallet !== "undefined") {
        const provider = await embeddedWallet?.getEthereumProvider();
        const balance = await provider?.request({
          method: 'eth_getBalance',
          params: [embeddedWallet?.address, 'latest'], // 'latest' 表示最新的区块
        });
        const balanceInEth = ethers.formatEther(balance);
        setBalance(parseFloat(balanceInEth))
      }
    } catch (error) {
    }
  }

  const setBuyInfo = async () => {
    try {
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + address)
      console.log('buyAmount:' + buyAmount)
      const price = await contract.getBuyPrice(
        // eslint-disable-next-line no-undef
        address, BigInt(buyAmount)
      );
      const totalPrice = await contract.getBuyPriceAfterFee(
        // eslint-disable-next-line no-undef
        address, BigInt(buyAmount)
      );
      const buyBalanceInEth = ethers.formatEther(price);
      const buyTotalEth = ethers.formatEther(totalPrice);
      setBuyEth(buyBalanceInEth)
      setTotalBuyEth(buyTotalEth)
    } catch (error) {

    }

  }

  const setSellInfo = async () => {
    try {
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + address)
      console.log('sellAmount:' + sellAmount)
      const price = await contract.getSellPrice(
        // eslint-disable-next-line no-undef
        address, BigInt(sellAmount)
      );
      const totalPrice = await contract.getSellPriceAfterFee(
        // eslint-disable-next-line no-undef
        address, BigInt(sellAmount)
      );
      const sellBalanceInEth = ethers.formatEther(price);
      const sellTotalEth = ethers.formatEther(totalPrice);
      setSellEth(sellBalanceInEth)
      setTotalSellEth(sellTotalEth)
    } catch (error) {

    }

  }

  //获取目标用户信息
  const getUserData = async () => {
    let result = await get("/user/" + id);
    let { code, data } = result;
    if (code === 1000) {
      setUser(data);
      setAddress(data.club.address)
    }
  };

  //个人信息
  const getSelfData = async () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let result = await get("/user/" + userInfo.id);
    let { code, data } = result;
    if (code === 1000) {
      setSelfData(data);
    }
  };

  //持有key数量
  const getOwnData = async (id) => {
    let result = await get("/club/own/" + id);
    let { code, data } = result;
    if (code === 1000) {
      setOwn(data);
      setLoading(false)
    }
  };

  const handleSell = () => {
    if (sellRef.current) {
      sellRef.current.togglePopup();
    }
  };

  const minusBuyAmount = () => {
    if (buyAmount > 1) {
      setBuyAmount(pre => pre - 1)
    }
  }

  const plusBuyAmount = () => {
    setBuyAmount(pre => pre + 1)
  }

  const minusSellAmount = () => {
    if (sellAmount > 1) {
      setSellAmount(pre => pre - 1)
    }
  }

  const plusSellAmount = () => {
    if (id === selfData.id) {
      if (sellAmount + 1 < own) {
        setSellAmount(pre => pre + 1)
      } else {
        toast.info('At least one key must be retained.')
      }
    } else {
      if (sellAmount + 1 <= own) {
        setSellAmount(pre => pre + 1)
      } else {
        toast.info('Maximum quantity sold has been reached.')
      }
    }
  }

  const handleBuy = async () => {
    if (buyRef.current) {
      buyRef.current.togglePopup();
    }
  };

  const buy = async () => {
    try {
      setIsBuying(true);
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      const price = await contract.getBuyPriceAfterFee(
        // eslint-disable-next-line no-undef 
        address, BigInt(buyAmount)
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + address)
      console.log('walletAddress:' + embeddedWallet?.address)
      console.log('buyAmount:' + buyAmount)
      console.log("price:" + price)
      const txResponse = await contract.buyShares(
        // eslint-disable-next-line no-undef 
        address, BigInt(buyAmount), { from: embeddedWallet?.address, value: price }
      );
      console.log("Transaction sent:", txResponse.hash);
      // await txResponse.wait();
      let postData = {
        club_address: address,
        key_num: buyAmount,
        key_price: -(totalBuyEth),
        transaction: txResponse.hash,
      };
      let result = await post("/trade/buy", postData);
      let { code, data, msg } = result;
      if (code === 1000) {
        await getOwnData(address)
        await getUserData()
        if (active !== 0) {
          setActive(0)
        } else {
          await getMembers()
        }
        buyRef.current.togglePopup()
        //访问成功
      } else {
        toast.error(msg)
      }
    } catch (error) {
      console.error(error);
      toast.error('transaction canceled or failed')
      buyRef.current.togglePopup()
    } finally {
      setIsBuying(false);
    }
  };

  const sell = async () => {
    try {
      setIsBuying(true);
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + address)
      console.log('sellAmount:' + sellAmount)
      const txResponse = await contract.sellShares(
        // eslint-disable-next-line no-undef 
        address, BigInt(sellAmount), { from: embeddedWallet?.address, value: 0 }
      );
      console.log("Transaction sent:", txResponse.hash);
      // await txResponse.wait();
      let postData = {
        club_address: address,
        key_num: sellAmount,
        key_price: Number(totalSellEth),
        transaction: txResponse.hash,
      };
      let result = await post("/trade/sell", postData);
      let { code, data, msg } = result;
      if (code === 1000) {
        await getOwnData(address)
        await getUserData()
        if (active !== 0) {
          setActive(0)
        } else {
          await getMembers()
        }
        sellRef.current.togglePopup()
        //访问成功
      } else {
        toast.warning(msg)
      }
    } catch (error) {
      toast.error('transaction canceled or failed')
      sellRef.current.togglePopup()
    } finally {
      setIsBuying(false);
    }
  };

  const linkToX = () => {
    if (user.twitter_name)
      // window.location.href = "https://twitter.com/" + user.twitter_name
      window.open("https://twitter.com/" + user.twitter_name, "_blank")
  }

  const getMembers = async () => {
    if (page === 1) {
      let result = await get(`/club/members/${id}?page=${page}&size=${size}`)
      if (result.code === 1000) {
        setMembers(result.data.list)
        setTotal(result.data.total)
        let tempTabs = [...tabs];
        tempTabs[0].name = `Members(${result.data.total})`;
        setTabs(tempTabs);
      }
    } else if ((page - 1) * size < total) {
      let result = await get(`/club/members/${id}?page=${page}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...members]
        let currentList = tempList.concat([...list])
        setMembers(currentList)
        setTotal(total)
        let tempTabs = [...tabs];
        tempTabs[0].name = `Members(${result.data.total})`;
        setTabs(tempTabs);
      }

    }
  }

  const getJoined = async () => {
    if (page === 1) {
      let result = await get(`/club/joined/${id}?page=${page}&size=${size}`)
      if (result.code === 1000) {
        setJoined(result.data.list)
        setTotal(result.data.total)
        let tempTabs = [...tabs];
        tempTabs[1].name = `Joined(${result.data.total})`;
        setTabs(tempTabs);
      }
    } else if ((page - 1) * size < total) {
      let result = await get(`/club/joined/${id}?page=${page}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...joined]
        let currentList = tempList.concat([...list])
        setJoined(currentList)
        setTotal(total)
        let tempTabs = [...tabs];
        tempTabs[1].name = `Joined(${total})`;
        setTabs(tempTabs);
      }
    }
  }

  const getActivities = async () => {
    if (page === 1) {
      let result = await get(`/club/activity/${id}?page=${page}&size=${size}&type=${type}`)
      if (result.code === 1000) {
        setActivities(result.data.list)
        setTotal(result.data.total)
      }
    } else if ((page - 1) * size < total) {
      let result = await get(`/club/activity/${id}?page=${page}&size=${size}&type=${type}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...activities]
        let currentList = tempList.concat([...list])
        setActivities(currentList)
        setTotal(total)
      }
    }
  }

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    toast.success('copied!')
  };

  return (
    <div
      className="w-full flex flex-col h-full max-w-[768px] bg-cover bg-no-repeat bg-center overflow-hidden"
    // style={{ backgroundImage: "url(/images/club.png)" }}
    >
      <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
      <LoadingContent show={loading ? true : undefined} />
      <div className="flex flex-none flex-col bg-gradient-to-b from-[#0e051f] to-[#2b0946] rounded-b-3xl">
        <div className="p-4 flex flex-row items-center text-xs">
          <div className="w-6 cursor-pointer mr-3" onClick={() => navigate(-1)}>
            <img className="w-4" src="/images/back.png" alt="back" />
          </div>
          <div className="w-16 h-16 flex items-center justify-center rounded-full border-white
           overflow-hidden bg-black bg-opacity-50 border-solid border-2" onClick={linkToX}>
            <img src={user.avatar} alt="" className="w-16"
              onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
          </div>
          <div className="flex-1 mx-2 flex flex-col">
            <div className="flex-1 flex-row flex items-center">
              <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
                <strong className="text-sm">{user.name}</strong>
              </div>
              {user.twitter_name ? <div
                style={{
                  background: "url(/images/twitter.png) no-repeat center center",
                  backgroundSize: "100%",
                }}
                className="p-1 mx-1 flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
              ></div> : null}
            </div>
            <div className="flex-1 text-sm">
              <strong>You own <span className="text-[#3c7cff]">{own}</span> {own ? "keys" : "key"}</strong>
            </div>
            <div className="flex-1 flex mt-1 text-sm">
              <div className="px-2 bg-[#411e60] py-0.5 rounded-full py-0 relative text-center">
                {members.length} Members，{joined.length} Joined
              </div>
            </div>
          </div>
        </div>
        <div className="pt-1 pb-4 px-4 flex justify-between text-sm">
          <div className="flex-1 flex flex-col items-center justify-center bg-[#411e60] py-3 rounded-2xl">
            <div className="text-[#a08fb0] flex-1 flex items-center justify-center mb-1">
              Portfolio
            </div>
            <div className="flex-1 flex items-center justify-center">
              <img
                src="/images/eth.png"
                alt=""
                className="w-[20px] h-[20px] mr-1"
              />
              <strong>
                {user.club.value > 1 ? formatNumber(user.club.value) : truncateDecimal(user.club.value, 5)}
              </strong>
            </div>
            {/* <div className="absolute top-1/6 right-0 h-2/3 bg-[#3e2d47] w-[1px]"></div> */}
          </div>
          <div className="flex-1 flex flex-col items-center justify-center bg-[#411e60] py-3 rounded-2xl mx-4">
            <div className="text-[#a08fb0] flex-1 flex items-center justify-center mb-1">
              Key Price
            </div>
            <div className="flex-1 flex items-center justify-center">
              {/* <div className="w-4 h-4 mr-1  p-1 flex items-center rounded-full bg-white bg-opacity-20">
              <img src="/svg/eth.svg" alt="eth" />
            </div> */}
              <img
                src="/images/eth.png"
                alt=""
                className="w-[20px] h-[20px] mr-1"
              />
              <strong>
                {user.club.price > 1 ? formatNumber(user.club.price) : truncateDecimal(user.club.price, 5)}
              </strong>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center bg-[#411e60] py-3 rounded-2xl">
            <div className="text-[#a08fb0] flex-1 flex items-center justify-center mb-1">
              Rank
            </div>
            <div className="flex-1 flex items-center justify-center">
              <strong>{user.club.rank}</strong>
            </div>
            {/* <div className="absolute top-1/6 left-0 h-2/3 bg-[#3e2d47] w-[1px]"></div> */}
          </div>
        </div>
      </div>
      <div className="px-4 py-2">
        <Tab
          tabs={tabs}
          active={active}
          onSelect={setActive}
          className="text-base"
        ></Tab>
      </div>
      <div className="flex flex-col px-4 overflow-y-auto pb-28" ref={scrollRef}>
        {active === 0 && members.length ?
          members.map((data, index) => <ClubItem data={data} key={index} own={id} tag={'members'} />) : null}
        {active === 1 && joined.length ?
          joined.map((club, index) => <ClubItem data={club} key={index} own={id} tag={'joined'} />) : null}
        {(active === 2 || active === 3) && activities.length ?
          activities.map((club, index) => <PersonActivity data={club} key={index} own={id} />) : null}
      </div>

      <div className="flex bg-[#0f0520] rounded-t-3xl py-4 px-3 flex-col fixed bottom-0 h-28 w-full max-w-[768px]">
        {own === 0 ? (
          <div className="flex flex-row items-center justify-center text-xs mb-2 w-full">
            You need a{" "}
            <img className="h-3 mx-2" src="/images/key-white.png" alt="key" />
            to access
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center text-xs mb-2 w-full">
            You own {own} key
          </div>
        )}

        {own !== 0 ? <div className="flex flex-1 flex-row items-center w-full">
          <div className="flex flex-1 px-1">
            <Button
              className="w-full h-10 bg-white flex items-center justify-center"
              onClick={handleSell}
              disabled={own === 0 || (id === selfData.id && own === 1)}
            >
              <strong className="text-black">SELL</strong>
            </Button>
          </div>
          <div className="flex flex-1 px-1">
            <div
              className="w-full h-10 bg-[#281d2e] rounded-full flex items-center justify-center"
            >
              <img className="h-5" src="/images/key-white.png" alt="key" />{" "}
              <div className="pl-2">x {own} </div>
            </div>
          </div>
          <div className="flex flex-1 px-1">
            <Button
              className="w-full h-10 rounded-full flex items-center justify-center"
              onClick={handleBuy} disabled={selfData.club.key === 0 && id !== selfData.id}
            >
              <strong>BUY</strong>
            </Button>
          </div>
        </div> :
          <div className="flex flex-1 flex-row items-center w-full">
            <Button
              className="w-full h-10 rounded-full flex items-center justify-center"
              onClick={handleBuy} disabled={selfData.club.key === 0 && id !== selfData.id}
            >
              <strong>BUY</strong>
            </Button>
          </div>}

      </div>

      <Modal ref={sellRef} title="Sell Keys" type="bottom" backVisible={setSellVisible}>
        <div className="flex flex-col items-center my-4">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex text-sm items-center">
              You Own
              <img className="h-3 ml-2" src="/images/key-white.png" alt="key" />{" "}
              <div className="pl-2">x {own}</div>
            </div>
            <div className="px-3 bg-[#3e2647] h-6 flex items-center justify-around rounded-full"
              onClick={addClick}>
              <div className="text-sm"> {balance.toFixed(7)} $BNB</div>
              <div className={`border flex items-center justify-center rounded-full h-[16px] w-[16px] px-1 ml-2`}>
                <img src="/images/plus.png" alt="" />
              </div>
            </div>
          </div>
          <div className="mt-3 w-20 h-20 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
            <img src={user.avatar} alt="" className="w-20 h-20"
              onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
          </div>
          <div className="bg-white rounded-full py-1 px-6 relative top-[-10px]">
            <strong className="text-[#000]">{sellEth} $BNB</strong>
          </div>
          <div className="text-xs mb-1">{user.name}</div>
          {user.twitter_name ? <div className="text-xs mb-1">@{user.twitter_name}</div> : null}
          <div
            className="w-full py-2 my-2 rounded-full bg-[#1c0d28]"
            style={{ border: "2px solid #93899b" }}
          >
            <div className="flex items-center justify-between px-3">
              <div className={`${sellAmount > 1 ? "bg-white" : "bg-[#665378]"} flex items-center justify-center rounded-full h-[24px] w-[24px] px-1`}
                onClick={minusSellAmount}>
                {sellAmount > 1 ? <img src="/images/black-minus.png" alt="" style={{ height: 20 }} /> :
                  <img src="/images/fog-minus.png" alt="" style={{ height: 20 }} />}
              </div>
              <div className="w-20 flex flex-row justify-center">
                <EditableDiv data={sellAmount} backValue={setSellAmount}>
                  <img className="h-5" src="/images/key-white.png" alt="key" />
                  <div className="pl-2">x {!isNaN(sellAmount) ? sellAmount : ''}</div>
                </EditableDiv>
              </div>
              <div className={`${(id === selfData.id && sellAmount + 1 < own)
                || (id !== selfData.id && sellAmount + 1 <= own) ? "bg-white" : "bg-[#665378]"}
               flex items-center justify-center rounded-full h-[24px] w-[24px] p-[2px]`}
                onClick={plusSellAmount}>
                {(id === selfData.id && sellAmount + 1 < own) || (id !== selfData.id && sellAmount + 1 <= own) ?
                  <img src="/images/black-plus.png" alt="" style={{ height: 20 }} /> :
                  <img src="/images/fog-plus.png" alt="" style={{ height: 20 }} />}
              </div>
            </div>
          </div>
          <div
            className="w-full py-4 px-4 rounded-3xl flex flex-col mb-8 bg-[#1c0d2a]"
          >
            <div className="flex flex-row justify-between">
              <div className="text-[#878788]">Total(inc.fees)</div>
              <div className="flex flex-col">
                <div className="w-32 break-all text-right">{truncateDecimal(totalSellEth, 7)} $BNB</div>
                {/* <div className="text-[#878788]">USD $ {totalSellEth * rate}</div> */}
              </div>
            </div>
            {!isOpen ? <div className="flex justify-center mt-2" onClick={() => { setIsOpen(!isOpen) }}>
              <img src="/images/arrow-bottom.png" alt="" className="h-2" />
            </div> : null}
            {isOpen ?
              <div className="border-t border-[#3f3e41] mt-5 flex flex-col text-xs">
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">From</div>
                  <div className="flex flex-row items-center">
                    <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {selfData.club.address}
                    </div>
                    <img src="/images/copy.png" alt="" className="w-[12px] h-[12px]" onClick={() => handleCopy(selfData.club.address)} />
                  </div>
                </div>
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">To</div>
                  <div className="flex flex-row items-center">
                    <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {address}
                    </div>
                    <img src="/images/copy.png" alt="" className="w-[12px] h-[12px]" onClick={() => handleCopy(address)} />
                  </div>
                </div>
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">Key</div>
                  <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {truncateDecimal(parseFloat(sellEth), 10)} $BNB
                    {/* {buyEth} $BNB */}
                  </div>
                </div>
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">Fees</div>
                  <div className="max-w-40 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {truncateDecimal(parseFloat(sellEth - totalSellEth), 10)} $BNB
                  </div>
                </div>
                <div className="flex justify-center my-2" onClick={() => { setIsOpen(!isOpen) }}>
                  <img src="/images/arrow-bottom.png" alt="" className="h-2" style={{ transform: 'rotateX(180deg)' }} />
                </div>
              </div>
              : null}
          </div>
          <button
            className="w-[calc(100%-2rem)] h-10 bg-[#6450fc] rounded-full text-white flex items-center justify-center my-4 text-sm absolute bottom-0"
            onClick={sell} disabled={isBuying || isNaN(sellAmount) || sellAmount < 1 ||
              sellAmount > own || (sellAmount + 1 > own && id === selfData.id)
            }
          >
            <strong>SELL NOW</strong>
          </button>
        </div>
      </Modal>

      <Modal ref={buyRef} title="Buy Keys" type="bottom" backVisible={setBuyVisible}>
        <div className="flex flex-col items-center my-4">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex text-sm items-center flex-1"> You Own
              <img className="h-3 ml-2" src="/images/key-white.png" alt="key" />{" "}
              <div className="pl-2">x {own}</div>
            </div>
            <div className="px-3 bg-[#3e2647] h-6 flex items-center justify-around rounded-full"
              onClick={addClick}>
              <div className="text-sm"> {balance.toFixed(7)} $BNB</div>
              <div className={`border flex items-center justify-center rounded-full h-[16px] w-[16px] px-1 ml-2`}>
                <img src="/images/plus.png" alt="" />
              </div>
            </div>
          </div>
          <div className="mt-3 w-20 h-20 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
            <img src={user.avatar} alt="" className="w-20 h-20"
              onError={(e) => { e.target.onerror = null; e.target.src = "/images/avatar.png" }} />
          </div>
          <div className="bg-white rounded-full py-1 px-6 relative top-[-10px]  flex flex-row">
            <strong className="text-[#000] ">{buyEth} $BNB</strong>
          </div>
          <div className="text-xs mb-1">{user.name}</div>
          {user.twitter_name ? <div className="text-xs mb-1">@{user.twitter_name}</div> : null}
          <div
            className="w-full py-2 my-2 rounded-full bg-[#1c0d28]"
            style={{ border: "2px solid #93899b" }}
          >
            <div className="flex items-center justify-between px-3">
              <div className={`${buyAmount > 1 ? "bg-white" : "bg-[#665378]"} 
              flex items-center justify-center rounded-full h-[24px] w-[24px] px-1`}
                onClick={minusBuyAmount}>
                {buyAmount > 1 ? <img src="/images/black-minus.png" alt="" style={{ height: 20 }} /> :
                  <img src="/images/fog-minus.png" alt="" style={{ height: 20 }} />}
              </div>
              <div className="w-20 flex flex-row justify-center">
                <EditableDiv data={buyAmount} backValue={setBuyAmount}>
                  <img className="h-5" src="/images/key-white.png" alt="key" />
                  <div className="pl-2">x {!isNaN(buyAmount) ? buyAmount : ''}</div>
                </EditableDiv>
                {/* <img className="h-5" src="/images/key-white.png" alt="key" />
                <div className="pl-2">x {buyAmount}</div> */}
              </div>
              <div className={`bg-white flex items-center justify-center rounded-full h-[24px] w-[24px] px-1`}
                onClick={plusBuyAmount}>
                <img src="/images/black-plus.png" alt="" />
              </div>
            </div>
          </div>
          <div
            className="w-full py-4 px-4 rounded-3xl flex flex-col mb-8 bg-[#1c0d2a]"
          >
            <div className="flex flex-row justify-between">
              <div className="text-[#878788]">Total(inc.fees)</div>
              <div className="flex flex-col">
                <div className="w-32 break-all text-right">{truncateDecimal(totalBuyEth, 7)} $BNB</div>
                {/* <div className="text-[#878788] w-32 break-all text-right">
                  USD $ {totalBuyEth * rate}</div> */}
              </div>
            </div>
            {!isOpen ? <div className="flex justify-center mt-2" onClick={() => { setIsOpen(!isOpen) }}>
              <img src="/images/arrow-bottom.png" alt="" className="h-2" />
            </div> : null}
            {isOpen ?
              <div className="border-t border-[#3f3e41] mt-5 flex flex-col text-xs">
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">From</div>
                  <div className="flex flex-row items-center">
                    <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {selfData.club.address}
                    </div>
                    <img src="/images/copy.png" alt="" className="w-[12px] h-[12px]" onClick={() => handleCopy(selfData.club.address)} />
                  </div>
                </div>
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">To</div>
                  <div className="flex flex-row items-center">
                    <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {address}
                    </div>
                    <img src="/images/copy.png" alt="" className="w-[12px] h-[12px]" onClick={() => handleCopy(address)} />
                  </div>
                </div>
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">Key</div>
                  <div className="max-w-32 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {truncateDecimal(parseFloat(buyEth), 10)} $BNB
                    {/* {buyEth} $BNB */}
                  </div>
                </div>
                <div className="flex flex-row justify-between my-2 items-center">
                  <div className="text-[#878788]">Fees</div>
                  <div className="max-w-40 text-right overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {truncateDecimal(parseFloat(totalBuyEth - buyEth), 10)} $BNB
                  </div>
                </div>
                <div className="flex justify-center my-2" onClick={() => { setIsOpen(!isOpen) }}>
                  <img src="/images/arrow-bottom.png" alt="" className="h-2" style={{ transform: 'rotateX(180deg)' }} />
                </div>
              </div>
              : null}
          </div>
          <button
            className="w-[calc(100%-2rem)] bg-[#6450fc] h-10 text-white rounded-full text-black flex 
            items-center justify-center my-4 text-sm absolute bottom-0"
            onClick={buy} disabled={isBuying || isNaN(buyAmount) || buyAmount < 1 ||
              (selfData.club.key === 0 && id !== selfData.id) || balance < totalBuyEth}
          >
            <strong>BUY NOW</strong>
          </button>
        </div>
      </Modal>

      <Modal
        ref={addRef}
        title="Deposit $BNB to your NEXTUP Wallet Address"
        closeIcon={false}
      >
        <div className="w-full flex justify-around flex-col flex-1 items-center">
          <div className="flex flex-row items-center mt-2 break-all">
            {selfData.club.address}
            <img
              src="/images/copy.png"
              alt=""
              style={{
                height: 14,
                width: 14,
                marginLeft: 10,
                cursor: "pointer",
              }}
              onClick={() => handleCopy(selfData.club.address)}
            />
          </div>
          <Button className="h-8 w-40 mt-2" onClick={addClick}>
            <strong>GOT IT</strong></Button>
        </div>
      </Modal>

    </div>
  );
};

export default Club;


const clubList = [
  {
    uid: "1",
    avatar: "/images/avatar.png",
    name: "Guest-123123",
    isManager: true,
    isBinded: true,
    price: 123,
    energy: 1234,
    keys: 5,
  },
  {
    uid: "2",
    avatar: "/images/avatar.png",
    name: "Guest-321321",
    isManager: true,
    isBinded: false,
    price: 123,
    energy: 1234,
    keys: 5,
  },
  {
    uid: "3",
    avatar: "/images/avatar.png",
    name: "Guest-312312",
    isManager: false,
    isBinded: true,
    price: 123,
    energy: 1234,
    keys: 5,
  },
  {
    uid: "4",
    avatar: "/images/avatar.png",
    name: "Guest-312312",
    isManager: false,
    isBinded: true,
    price: 123,
    energy: 1234,
    keys: 5,
  },
  {
    uid: "5",
    avatar: "/images/avatar.png",
    name: "Guest-312312",
    isManager: false,
    isBinded: true,
    price: 123,
    energy: 1234,
    keys: 5,
  },
];