export const REACT_APP_PRIVY_APP_ID = "clt6uxbgr00zt6z0sfb756pvz"
export const REACT_APP_REST_API = "https://api.nextup.gg/v1"
// export const REACT_APP_REST_API = "http://192.168.31.136:8080/v1"
export const REACT_APP_CONTRACT = "0x6554665806b2dbB1c191FDa357efA043359F7F2A"
// export const REACT_APP_REST_API = "http://192.168.199.140:8080/v1"

//test
// export const REACT_APP_PRIVY_APP_ID = "clrxkednb09drl10naepy5soj"
// export const REACT_APP_REST_API = "https://back.jumpup.app/v1"
// export const REACT_APP_CONTRACT = "0x582D041EA37fC7fB8281227e327d60e72b0143B7"


//local
// export const REACT_APP_PRIVY_APP_ID = "clre3k7o600ygl40f4rkzhcui"
// export const REACT_APP_CONTRACT = "0x120d1aaDC0E55d844c6e1D5f184a0F07dEbBc1E5"
// export const REACT_APP_REST_API = "http://192.168.199.140:8080/v1"
// export const REACT_APP_REST_API = "http://localhost:8080/v1" 